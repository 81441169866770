.policy-owner-details {
  width: 100vw;
  max-width: 1100px;
  box-sizing: content-box;

  .container {
    padding: 10px 40px;
  }

  .details {
    &__header {
      background-color: var(--primary-color);
      padding: 40px 40px;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;

      &-title {
        font-size: 20px;
        font-family: 'Avenir Next GEO W05 Bold';
      }

      &-description {
        font-size: 15px;
        font-family: 'Avenir Next GEO W05 Regular';
        margin-top: 5px;
      }
    }

    &__sub-header {
      background: #f4f4f4;
      display: flex;
      align-items: center;
      gap: 40px;
    }

    &__item {
      font-size: 15px;
      display: flex;
      align-items: center;
      gap: 5px;
      &-title {
        font-family: 'Avenir Next GEO W05 Bold';

        &--primary {
          color: var(--primary-color);
        }
      }

      .icon-edit {
        cursor: pointer;
      }
    }

    &__reopen {
      margin-top: 20px;

      label {
        margin-right: 20px;
      }
    }
  }
}
