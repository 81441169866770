.manager-exclusion-schedule {
  display: flex;
  background-color: #fff;

  .manager-exclusion-schedule-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;

    &__main {
      background-color: #fff;
      padding: 20px 35px;
      height: 100%;
      width: calc(100% - 150px);

      &--painel {
        max-width: calc(100vw - 170px);
      }
    }
  }

  .manager-exclusion-schedule-title {
    padding: 40px;
    padding-left: 0;

    &__title {
      font-family: 'Avenir Next GEO W05 Heavy';
      font-size: 20px;
      color: var(--color-grey);
    }
  }

  .manager-exclusion-schedule-content {
    display: grid;
    grid-template-columns: 300px 240px 150px 1fr;
    column-gap: 10px;
    box-sizing: border-box;

    &__input-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100px;
      width: 100%;
    }

    &__label {
      display: block;
      width: 100%;
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 14px;
      color: var(--color-grey);
      margin-bottom: 5px;
    }

    &__label-contract {
      display: block;
      width: 100%;
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 14px;
      color: var(--color-grey);
      margin-bottom: 5px;
      padding-left: 38px;
    }

    &__input-group {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &__input-group-button {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 100%;
    }

    &__input {
      background-color: #fff;
      border: 1px solid var(--color-tab-dark-grey);
      color: var(--color-grey);
      border-radius: 6px;
      font-size: 14px;
      padding: 5px;
      height: 31px;
      width: 80%;

      &::placeholder {
        text-align: start;
        color: #c1c1c1;
      }

      &:focus-visible {
        outline-color: var(--color-tab-dark-grey);
      }
    }

    &__button {
      background-color: #02024E;
      border: 1px solid #d1d5db;
      border-radius: 6px;
      color: white;
      cursor: pointer;
      height: 30px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      &:hover {
        text-decoration: none;
      }
    }

    &__select {
      font-family: 'Avenir Next GEO W05 Regular';
      background-color: #fff;
      border: 1px solid var(--color-tab-dark-grey);
      color: var(--color-grey);
      border-radius: 6px;
      padding: 5px;
      font-size: 14px;
      width: 70%;
      text-align: start;
      height: 30px;

      &::placeholder {
        text-align: start;
        color: #c1c1c1;
        padding-left: 3px;
      }

      &:focus-visible {
        outline-color: var(--color-tab-dark-grey);
      }

      option {
        font-size: 14px;
      }
    }

    &__period {
      display: flex;
      flex-direction: row;
    }

    &__icon {
      padding-top: 2px;
      cursor: pointer;
    }

    &__period-inputs {
      display: flex;
      flex-direction: column;
      padding: 5px;
    }

    &__period-label {
      display: block;
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 14px;
      color: var(--color-grey);
      margin-left: 6px;
      margin-bottom: 2px;
    }

    &__input-date {
      border: 1px solid var(--color-tab-dark-grey);
      border-radius: 6px;
      display: inline-block;
      text-align: center;
      font-size: 12px;
      font-family: 'Avenir Next GEO W05 Regular';
      margin-left: 5px;
      height: 25px;
      width: 100px;
      z-index: 3000;
  
      &:focus-visible {
        outline: 1px var(--color-tab-dark-grey) auto;
      }
    }

    &__checkbox-group {
      display: flex;
      flex-direction: row;
    }

    &__checkbox {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    &__label-checkbox {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 14px;
      color: var(--color-grey);
      padding-top: 6px;
      width: 100%;
    }

    &__warning {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 10px 20px 10px;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      color: var(--color-red);
    }
  }
}