.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  border-radius: 10px;
  background-color: #fff;
  width: 450px;
  animation: modal-enter 0.2s forwards;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &--leave {
    animation: modal-leave 0.2s forwards;
  }

  @keyframes modal-enter {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes modal-leave {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: #f5f5f6;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__title {
    font-family: 'Avenir Next Geo W05 Demi';
    font-size: 18px;
    font-weight: 700;
  }

  &__close-icon {
    cursor: pointer;
  }

  &__content {
    padding: 20px;
  }
}
