.tasks-table-tbody-tr {
  display: grid;
  grid-template-columns: 80px 1fr 80px 100px 1fr 0.5fr 0.5fr 0.5fr 100px 100px;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;

  &:hover {
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;
    background-color: var(--color-tab-light-grey);
  }

  &__title-pop-up {
    display: flex;
    position: absolute;
    align-items: center;
    background-color: #c1c1c1;
    padding: 5px;
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);

    height: 14px;
    max-width: 500px;
  }

  td:first-child {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    border-right: 2px solid var(--color-tab-light-grey);
    padding: 5px;
    height: 25px;
  }

  td:last-child {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    border-right: none;
    padding: 5px;
    height: 25px;
  }
}

.tasks-table-tbody-td {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 12px;
  font-family: 'Avenir Next GEO W05 Regular';
  color: var(--color-grey);
  border-right: 2px solid var(--color-tab-light-grey);
  padding: 5px;
  height: 25px;

  &__truncate {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
    padding-top: 8px;
  }

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 20px;
    width: 100%;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }
  }

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    font-size: 12px;
    width: 95%;
    text-align: start;
    height: 20px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 12px;
    }
  }
}

.tasks-table-tbody-td-container-input {
  display: flex;
  flex-direction: row;
  column-gap: 5px;

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    font-size: 14px;
    width: 85%;
    text-align: start;
    padding: 1px;
    padding-left: 3px;
    height: 20px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }

  &__icon {
    color: var(--color-green);
  }
}