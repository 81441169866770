.hr-users-management {
  display: flex;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.hr-users-management-content {
  width: calc(100% - 80px);
}

.hr-users-management-consult {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 100%;

    h1 {
      font-family: 'Avenir Next GEO W05 Heavy';
      font-size: 20px;
      color: var(--color-grey);
    }
  }
}

.hr-users-management-table {
  width: 100%;
  box-sizing: border-box;

  &__theader {
    display: grid;
    grid-template-columns: 1.5fr 2fr 1fr 0.7fr;
    border: 2px solid var(--color-tab-light-grey);
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    height: 33px;

    th {
      text-align: left;
      font-size: 16px;
      font-family: "Avenir Next GEO W05 Demi";
      color: var(--color-grey);
    }

    th:nth-child(1) {
      padding-left: 20px;
    }
  }

  &__tbody {
    display: flex;
    flex-direction: column;

    tr {
      display: grid;
      grid-template-columns: 1.5fr 2fr 1fr 0.7fr;
      border: 2px solid var(--color-tab-light-grey);
      border-top: none;
      padding-bottom: 10px;
      background-color: #fff;
      height: 33px;

      &:hover {
        border: 2px solid var(--color-tab-light-grey);
        border-top: none;
        background-color: var(--color-tab-light-grey);
      }

      td:first-child {
        padding-left: 15px;
      }

      td {
        display: flex;
        align-items: center;
        height: 50px;
  
        span {
          text-align: left;
          font-size: 16px;
          font-family: 'Avenir Next GEO W05 Regular';
          color: var(--color-grey);
        }
      }

      td:last-child > div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
}

.text-center-movement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  color: var(--color-grey);
  font-size: 18px;
  margin-top: 10vh;

  .circular-progress-loading {
    color: var(--color-light-blue-alt);
    margin-bottom: 30px;
  }
}
