.item-list {
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  box-sizing: border-box;
  font-size: 16px;
  height: 40px;

  &:hover {
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;
    background-color: var(--color-tab-light-grey);
  }
}

.item-list__data {
  width: 100%;
  display: grid;
  gap: 30px;
  grid-template-columns: 15px 3fr 150px 1fr 1fr 1fr 1fr 80px;
  font-size: 0.8rem;

  div {
    height: 38.4px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .icon {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    button.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      border: none;
      font-size: 1.3rem;
      cursor: pointer;
      background: none;
      transition: 0.1s ease-in;
      border-radius: 50%;

      :hover {
        background: rgb(204 204 204 / 25%);
      }
    }
    .close {
      color: red;
    }
    .search {
      color: var(--color-dark-blue);
    }
  }
}

.item-list__data-checkbox {
  width: 25px;
  height: 25px;
}

.item-list__data-beneficios {
  display: flex;
}

.item-list__data-beneficios span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
  color: var(--color-dark-blue);
}

.item-list__data-beneficios svg {
  width: 20px;
  fill: currentColor;
}

.item-list__data-icon {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-edit-on-table {
  color: var(--color-dark-blue);
  margin: 5px;
  margin-bottom: 0;
  margin-bottom: -1px;
  margin-left: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.item-list__data-icon .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: none;
  font-size: 1.3rem;
  cursor: pointer;
  background: none;
  transition: 0.1s ease-in;
  border-radius: 50%;
}

.item-list__data-icon .btn:hover {
  background: var(--color-background-hover);
}

.item-list__data-icon .close {
  color: var(--color-red);
}

.item-list__data-icon .search {
  color: var(--color-dark-blue);
}

.item-list__item-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 1rem 0;
  border-top: 1px var(--color-grey) solid;
  margin-top: 20px;
}

.item-list__item-content-col div {
  margin-bottom: 1rem;
}

.item-list__item-content-documentos {
  grid-area: 2 / 1 / 2 / 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-list__item-content-documentos .btn-deteils {
  border: none;
  background: #f3ecff; /* Ajuste conforme necessário */
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  margin-right: 30px;
  padding: 2px 5px;
  border-radius: 5px;
  color: #62a0e2; /* Ajuste conforme necessário */
}

.item-list__item-content-valores {
  display: flex;
  align-items: center;
}

.item-list__item-content-valores svg {
  color: #62a0e2; /* Ajuste conforme necessário */
  margin-right: 10px;
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.item-list__item-content-text-color {
  color: #62a0e2; /* Ajuste conforme necessário */
}

.content-list {
  display: flex;
  flex-direction: column;
}

.content-list__header-list {
  box-sizing: border-box;
  display: grid;
  gap: 30px;
  grid-template-columns: 15px 3fr 150px 1fr 1fr 1fr 1fr 80px;
  font-family: 'Avenir Next GEO W05 Demi';
  border: 2px solid var(--color-tab-light-grey);
  align-items: center;
  color: var(--color-grey); /* Verifique e ajuste conforme necessário */
  user-select: none;
  height: 40px;
  width: 100%;
}

.content-list__header-list-col {
  justify-content: center;
  text-align: center;
}

.content-list__header-list-filter-data-abertura-icon {
  width: 17px;
}

.content-list__header-list-filter-data-abertura-icon:hover {
  cursor: pointer;
}

.content-list__header-list-filter-container {
  display: flex;
  align-items: center;
}

.edit-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  .button-edit,
  .delete-button {
    display: flex;
    align-items: center;
    border: 0;
    margin: 0;
    border-radius: 5px;
    color: var(--color-dark-blue);
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    & > span {
      margin-right: 10px;
    }
  }

  .delete-button {
    margin-left: 10px;
  }
}

.paper-popup-delete-confirmation {
  width: 500px;
  height: 80px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-options {
  margin: 0 10px;
  align-items: center;

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

.popup-options-container {
  margin-top: 5px;
}

.popup-message {
  font-weight: bold;
}

.check-icon {
  margin-right: 2px;
  color: var(--color-green);
}

.cross-icon {
  margin-right: 2px;
  color: var(--color-red);
}
