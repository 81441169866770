.table-atividades {
  display: flex;
  align-items: baseline;
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    width: 100%;
    overflow: auto;
    max-width: 100%;

    tr {
      display: grid;
      grid-template-columns: 40px 180px 70px 80px 100px 172px 60px 80px 90px 70px 82px 82px 72px;
      border: 1px solid #dddddd;
      width: max-content;
      align-items: center;
      column-gap: 20px;
      padding-left: 25px;
      padding-right: 25px;
      height: 33px;
    }

    thead {
      tr {
        text-align: left;
        font-size: 0.85em;
      }
    }

    tbody {
      display: flex;
      flex-direction: column;

      tr {
        font-size: 0.8em;
        padding-top: 5px;
        padding-bottom: 5px;
        border: 1px solid #dddddd;
        background-color: #ffffff;
        width: max-content;
        height: 30px;
      }

      td {
        display: flex;
        align-items: center;
        line-height: 14px;
        align-items: center;

        &.truncate {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 10px;
          display: block;
        }

        .details_button {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          padding: 4px;
          font-size: 1.25rem;
          color: #1e2552;
          transition: 0.1s ease-in;
          border-radius: 50%;

          :hover {
            background: rgba(204, 204, 204, 0.25);
          }
        }
      }

      .atividade {
        display: flex;
        align-items: center;
        column-gap: 8px;
        justify-content: center;
      }

      .select {
        appearance: none;
        border: none;
        font-family: 'Avenir Next Geo W05 Regular';
        width: 102px;
        height: 20px;
        text-overflow: ellipsis;
      }

      .select__trigger {
        padding-bottom: 0px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
        background-position-x: 100%;
        background-position-y: 50%;

        :focus {
          outline: none;
        }
      }

      .options {
        font-size: 0.95em;
        row-gap: 2px;
        border: none;

        :hover {
          background-color: #456aed;
        }

        ::-webkit-scrollbar-thumb {
          background: #456aed;
        }
      }

      .icon-alert {
        font-size: 1.5em;
      }

      .plus {
        color: #666666;
        font-size: 1em;

        :hover {
          cursor: pointer;
        }
      }

      .status-grey-circle-icon {
        color: #999999;
      }

      .status-green-circle-icon > circle {
        color: #008631;
      }

      .status-yellow-circle-icon {
        color: #fff266;
      }

      .status-red-circle-icon {
        color: #ff1919;
      }

      .MuiCheckbox-root {
        padding: 7px;

        svg {
          font-size: 14px;
        }
      }

      .Mui-checked {
        color: #2e8548;
      }
    }
  }
}
