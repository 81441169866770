.status-circle {
  .status-in-cancel-process-circle-icon {
    animation: blinking-in-cancel-process-icon 1.5s infinite;
  }

  .status-canceled-active-circle-icon {
    animation: blinking-canceled-icon 1.5s infinite;
  }

  .status-active-circle-icon > circle {
    animation: blinking-active-icon 1.5s infinite;
  }

  @keyframes blinking-active-icon {
    0% {
      color: #008631;
    }
    50% {
      color: #00c04b;
    }
    100% {
      color: #008631;
    }
  }

  @keyframes blinking-in-cancel-process-icon {
    0% {
      color: #d29e00;
    }
    50% {
      color: #ffd900;
    }
    100% {
      color: #d29e00;
    }
  }

  @keyframes blinking-canceled-icon {
    0% {
      color: #8b0000;
    }
    50% {
      color: #dc143c;
    }
    100% {
      color: #8b0000;
    }
  }
}
