.drawer-negocios {
  width: 1065px;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 5px;
    padding: 20px;
    padding-left: 65px;
    background-color: var(--primary-color);
    height: 110px;
    color: var(--color-white);
  }

  &__title {
    font-family: 'Avenir Next Geo W05 Heavy';
  }

  &__subtitle {
    font-family: 'Avenir Next Geo W05 Medium';
    font-size: 0.9em;
  }

  &__seguradora-nome {
    padding-top: 6px;
    margin-top: 6px;
    border-top: 1px solid var(--color-white);
    width: max-content;
    font-size: 0.9em;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 40px;
    padding-left: 65px;
  }

  &__linha-primeira {
    display: flex;
    justify-content: space-between;
  }

  &__coluna-primeira {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
  }

  &__select-estagio {
    select {
      width: 250px;
      appearance: none;
      border: 1px solid var(--color-genoa-grey);
      padding: 5px 12px 5px 5px;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23D9D9D9'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 94%;
      background-position-y: center;
      background-size: 8px;
      border-radius: 20px;

      :focus-visible {
        outline: 1px var(--color-genoa-light-blue) f5 auto;
      }
    }
  }

  &__info-column {
    display: flex;
    font-size: 0.9em;
    align-items: center;
    min-width: 150px;
  }

  &__span-header {
    font-family: 'Avenir Next Geo W05 Bold';
    margin-right: 7px;
  }

  &__status {
    display: flex;
    gap: 10px;

    &--verde {
      &:hover {
        background-color: #5bb347 !important;
        color: var(--color-white);
      }
    }

    &--cinza {
      &:hover {
        background-color: #878787 !important;
        color: var(--color-white);
      }
    }

    &--vermelho {
      &:hover {
        background-color: #f81111 !important;
        color: var(--color-white);
      }
    }
  }

  &__linha-segunda {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 0.9em;
  }

  &__atividades-table {
    border: 1px solid var(--color-medium-grey);
    border-radius: 10px;
  }

  &__atividades-table-header {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 0.5fr 1fr 0.5fr 0.5fr;
    row-gap: 20px;
    text-align: left;
    font-weight: 700;
    border-bottom: 1px solid var(--color-medium-grey);
    padding: 20px;
  }

  &__atividades-table-header-more {
    display: block;
    cursor: pointer;
  }

  &__atividades-header-title {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
  }

  &__atividades-header {
    font-family: 'Avenir Next Geo W05 Demi';
    color: var(--color-grey);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__atividades-header--p-top {
    padding-top: 25px;
  }

  &__descricao-atividade-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__descricao-atividade-input {
    border-radius: 16px;
    border: 1px solid;
    padding: 12px;
    font-family: 'Avenir Next Geo W05 Regular';
    top: 5px;
    height: 28px;
    width: calc(100% - 50px);

    :focus-visible {
      outline: 1px var(--color-genoa-light-blue) f5 auto;
    }
  }

  &__datepicker-container {
    display: flex;
    align-items: center;
  }

  &__custom-datepicker {
    border: none;
    border-bottom: 1px solid var(--color-genoa-blue);
    display: inline-block;
    overflow: hidden;
    font-family: 'Avenir Next Geo W05 Regular';
    height: 22px;
    width: 80px;

    &--active {
      border-bottom: 1px solid var(--color-genoa-blue);
    }

    .react-datepicker__day--selected:hover {
      color: white;
      background-color: var(--color-genoa-blue-hover);
      font-weight: bold;
    }

    .react-datepicker__day--selected {
      color: white;
      background-color: var(--color-genoa-blue);
      font-weight: bold;
    }
    .react-datepicker__day--keyboard-selected:hover {
      color: var(--color-genoa-blue);
      background-color: #f0f0f0;
    }

    .react-datepicker__day--keyboard-selected {
      color: var(--color-genoa-blue);
      background-color: white;
      font-weight: bold;
    }

    .react-datepicker__triangle::after {
      padding: 0px;
    }
  }

  &__select {
    align-self: center;
    appearance: none;
    border: none;
    font-family: 'Avenir Next Geo W05 Regular';
    width: 130px;
    height: 20px;

    &__trigger {
      border-bottom: none;
      background-position-y: 3px;
      height: 24px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-position-x: 100%;
      background-position-y: 50%;

      :hover {
        border-bottom: none;
      }

      :focus {
        outline: none;
      }
    }
  }

  &__options {
    display: inline-block;
    font-size: 0.95em;
    row-gap: 2px;
    border: none;

    ::-webkit-scrollbar-thumb {
      background: var(--color-genoa-blue);
    }
  }

  &__lista-atividades {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
    padding: 15px;
    border-radius: 10px;
  }

  &__buttons-container {
    display: flex;
    column-gap: 20px;
  }

  &__atividades-coluna-primeira {
    display: grid;
    grid-template-columns: 50px 4fr 100px 2fr 1fr;
    column-gap: 20px;

    .close-edit-btn {
      color: ff5555;
    }

    button {
      border: none;
      background: none;
      cursor: pointer;

      &--save-observacoes {
        background-color: var(--color-genoa-blue);
      }

      &--disabled {
        background-color: var(--color-genoa-disabled-grey);
      }
    }
  }

  &__check {
    font-size: 0.8em;
    color: var(--color-genoa-dark-blue);
  }

  &__trash {
    font-size: 0.9em;
    color: var(--color-genoa-dark-blue);
  }

  &__plus-atividade {
    font-family: 'Avenir Next Geo W05 Demi';
    border: none;
    cursor: pointer;
    color: var(--color-genoa-blue-hover);
    background: none;
    transition: 0.1s ease-in;
  }

  &__atividade {
    &--grey {
      color: var(--color-genoa-grey);
    }

    &--green {
      font-family: 'Avenir Next Geo W05 Demi';
      color: #008631;
    }

    &--yellow {
      font-family: 'Avenir Next Geo W05 Demi';
      color: #fff266;
    }

    &--red {
      font-family: 'Avenir Next Geo W05 Demi';
      color: #ff5555;
    }
  }

  &__atividades-coluna-segunda {
    display: grid;
    grid-template-columns: 15px 350px 80px 125px 30px;
    column-gap: 20px;
    align-items: center;
    justify-content: space-between;

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  &__check-concluidas {
    font-size: 0.95em;
    color: var(--color-green);
  }

  &__linha-terceira {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 0.9em;
  }

  &__lista-seguradoras {
    border: 1px solid var(--color-medium-grey);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__option {
      display: grid;
      grid-template-columns: 136px 142px 563.88px 85px;
      align-items: center;
      gap: 10px;
    }

    &__button {
      padding-top: 20px;
    }
  }

  &__linha-quarta {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 0.9em;
    padding-bottom: 100px;
  }

  &__textarea {
    resize: none;
    padding: 16px;
    border-radius: 10px;
    font-family: 'Avenir Next Geo W05 Regular';
    border: 1px solid var(--color-tab-dark-grey);

    &:focus-visible {
      border: 1px solid var(--color-tab-dark-grey);
      outline: none;
    }

    &::-webkit-scrollbar {
      width: 3px;
      padding: 16px 0px;
      background: rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--color-rh-light-grey);
      padding: 16px 0px;
      box-shadow: none;
    }
    &::-webkit-scrollbar-track {
      margin-top: 16px;
      margin-bottom: 16px;
      box-shadow: inset 0 0 2px rgb(0, 0, 0, 0);
    }
  }

  &__button-container {
    padding-left: 5px;
  }
}
