.inclusion-mov-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 1rem 0;
  padding-left: 59px;

  border-top: 2px dashed var(--color-tab-light-grey);
  border-right: 2px solid var(--color-tab-light-grey);
  border-left: 2px solid var(--color-tab-light-grey);
  border-bottom: 2px solid var(--color-tab-light-grey);
}

.inclusion-mov-card-information {
  display: flex;
  flex-direction: column;

  div:first-child, div:nth-child(2) {
    display: flex;
    gap: 5px;
  }

  &__label {
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
  }

  &__span {
    text-align: center;
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
  }
}

.inclusion-mov-card-plans-information {
  &--align-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    row-gap: 10px;
  }
}

.inclusion-mov-card-plans-information-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 5px;
  justify-content: center;
  width: 100%;

  &--plan-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  &--plan-form {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__label {
    font-family: 'Avenir Next Geo W05 Demi';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__label-info {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__icon {
    color: var(--color-dark-blue);
    margin-right: 10px;
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    text-align: start;
    height: 25px;
    width: 200px;
    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    color: var(--color-grey);
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    margin-left: 5px;
    height: 25px;
    width: 200px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }

    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
    }
  }
}
