.main-ds-button {
  display: flex;
  font-family: 'Avenir Next GEO W05 Demi';
  border: none;
  border-radius: 5px;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  color: #fff;
  transition: background 0.2s;

  &--small {
    padding: 6px 12px;
    font-size: 14px;
  }

  &--medium {
    padding: 11px 20px;
    font-size: 16px;
  }

  &--large {
    padding: 16px 28px;
    font-size: 18px;
  }

  &--primary {
    background: var(--color-primary-blue);

    &:hover:not(.main-ds-button--disabled) {
      background: var(--color-primary-blue-hover);
    }
  }

  &--secondary {
    background-color: var(--secondary-color);

    &:hover:not(.main-ds-button--disabled) {
      background: var(--secondary-color-hover);
    }
  }

  &--default {
    background-color: #fff;
    color: var(--color-grey);
    border: 1px solid #e5e5e5;

    &:hover:not(.main-ds-button--disabled) {
      background-color: #f8f8f8;
    }
  }

  &--disabled {
    background: var(--color-disabled-grey);
    color: var(--color-subtle-grey);
    cursor: not-allowed;
    pointer-events: none;

    &:hover {
      background: var(--color-disabled-grey);
    }
  }

  &--transparent {
    background-color: transparent;
    color: rgb(30, 37, 82);

    &:hover:not(.main-ds-button--disabled) {
      text-decoration: underline;
    }
  }

  &--transparent-disabled {
    background-color: transparent;
    color: var(--color-subtle-grey);
    cursor: not-allowed;

    &:hover:not(.main-ds-button--disabled) {
      text-decoration: none;
    }
  }

  &--cancel {
    background: rgb(255, 7, 87);
    color: #fff;

    &:hover:not(.main-ds-button--disabled) {
      background: rgba(255, 7, 87, 0.9);
    }
  }

  &--remove {
    background-color: transparent;
    color: rgb(255, 7, 87);

    &:hover:not(.main-ds-button--disabled) {
      color: rgba(255, 7, 87, 0.9);
    }
  }

  &--back {
    background-color: var(--color-light-grey-alt);

    &:hover:not(.main-ds-button--disabled) {
      background: var(--color-subtle-grey);
    }
  }

  &--save {
    background: var(--color-green);

    &:hover:not(.main-ds-button--disabled) {
      background: var(--color-hover-green);
    }
  }

  &--hr {
    background: var(--color-light-blue-alt);

    &:hover:not(.main-ds-button--disabled) {
      background: var(--color-strong-blue);
    }
  }
}