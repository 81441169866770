.broker-home {
  display: flex;
  height: 118vh;
}

.broker-home-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 20px;
  padding-left: 0;
  max-height: 105vh;
}

.broker-home-main {
  @media (max-height: 580px) {
    background: var(--color-white);
    padding: 13.4px;
    padding-left: 30.15px;
    padding-right: 30.15px;
  }

  @media (min-height: 580px) and (max-height: 650px) {
    background: var(--color-white);
    padding: 16px;
    padding-left: 36px;
    padding-right: 36px;
  }

  @media (min-height: 650px) and (max-height: 720px) {
    background: var(--color-white);
    padding: 18px;
    padding-left: 40.5px;
    padding-right: 40.5px;
  }

  @media (min-height: 720px) and (max-height: 820px) {
    background: var(--color-white);
    padding: 20px;
    padding-left: 45px;
    padding-right: 45px;
  }

  @media (min-height: 820px) and (max-height: 920px) {
    background: var(--color-white);
    padding: 22px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-height: 920px) and (max-height: 1120px) {
    background: var(--color-white);
    padding: 24px;
    padding-left: 55px;
    padding-right: 55px;
  }
}

.broker-home-main-content {
  display: block;
  padding-top: 10px;

  &__column-one {
    box-sizing: border-box;
  }

  &__column-two {
    display: flex;
    flex-direction: row;
    column-gap: 100px;
    padding-top: 20px;
    box-sizing: border-box;
  }

  &__column-three {
    padding-top: 20px;
    box-sizing: border-box;
  }
}

.circular-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25vh;
  &__h3 {
    color: var(--color-grey);
    margin-top: 10px;
  }
}

.circular-spinner{
  color: var(--primary-color);
  margin-bottom: 20px;
}
