.filter-atividades {
  display: flex;
  font-size: 0.8em;
  gap: 20px;

  &__title {
    font-family: 'Avenir Next Geo W05 Demi';
  }

  &__action {
    align-self: end;
  }

  &__section {
    display: flex;
    gap: 30px;

    .select-standard {
      width: 170px;
    }
  }

  &__section-label {
    color: var(--color-grey);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__radio-group {
    display: flex;
    gap: 15px;
  }

  &__radio-container {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }

  .MuiRadio-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }

  &__datepicker-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 370px;
    height: 25px;
    column-gap: 15px;
  }

  &__datepicker-container {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &__input-date {
    width: 100px;
    height: 25px;
    border: 2px solid #ddd;
    text-align: center;
    font-size: 0.75rem;
    border-radius: 7px;

    &:focus-visible {
      outline: 1px solid #456aed;
    }
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: min-content;
    height: 25px;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: white;
    color: #456aed;
    font-weight: bold;
  }

  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__year-text--selected {
    border-radius: 0.3rem;
    background-color: #456aed;
    color: #fff;
  }
}
