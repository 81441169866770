.my-activities {
  display: block;
  padding: 0;
}

.my-activities-content {
  border-radius: 6px;

  &__title {
    color: var(--color-grey);
    font-size: 20px;
    font-family: 'Avenir Next GEO W05 Bold';
  }
}

.my-activities-table {
  background-color: none;
  border-top: 2px solid var(--color-border-grey);
  border-left: 2px solid var(--color-border-grey);
  border-right: 2px solid var(--color-border-grey);
  border-radius: 6px;
  margin-top: 5px;

  th:last-child {
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      font-family: 'Avenir Next GEO W05 Demi';
      color: var(--primary-color);
      cursor: pointer;
    }
  }

  &__theader {
    text-align: left;
    width: 100%;
  }

  &__theader-tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-bottom: 2px solid var(--color-border-grey);
  }

  &__theader-th {
    cursor: pointer;
    border-right: 2px solid var(--color-border-grey);
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
    padding: 10px;
  }

  &__tbody {
    width: 100%;
  }

  &__tbody-tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    td:last-child {
      font-family: 'Avenir Next Geo W05 Regular';
      color: var(--color-grey);
      border-bottom: 2px solid var(--color-border-grey);
      text-align: left;
      font-size: 12px;
      padding: 10px;

      cursor: pointer;

      &:hover {
        font-family: 'Avenir Next GEO W05 Demi';
        color: var(--primary-color);
        cursor: pointer;
      }
    }
  }

  &__tbody-td {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    border-right: 2px solid var(--color-border-grey);
    border-bottom: 2px solid var(--color-border-grey);
    text-align: left;
    font-size: 12px;
    padding: 10px;
  }
}

.title-tbody {
  padding-left: 15px;
}

.label-tbody {
  cursor: pointer;

  &:hover {
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--primary-color);
    cursor: pointer;
  }
}
