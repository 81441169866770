.ds-button {
  display: flex;
  font-family: 'Avenir Next GEO W05 Demi';
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #fff;

  &--broker {
    background: var(--color-primary-blue);
    &:hover:not(.ds-button--disabled) {
      background: var(--color-primary-blue-hover);
    }
  }

  &--hr {
    background: var(--color-light-blue-alt);
    &:hover:not(.ds-button--disabled) {
      background: var(--color-strong-blue);
    }
  }

  &--disabled {
    background: var(--color-disabled-grey);
    color: var(--color-subtle-grey);
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      background: var(--color-disabled-grey);
    }
  }

  &--transparent {
    background-color: transparent;
    color: rgb(30, 37, 82);
    &:hover {
      text-decoration: underline;
    }
  }

  &--cancel {
    color: #fff;
    background: rgb(255, 7, 87);
  }

  &--remove {
    background-color: transparent;
    color: rgb(255, 7, 87);
  }

  &--back {
    background-color: var(--color-light-grey-alt);
    &:hover {
      background: var(--color-subtle-grey);
    }
  }
}
