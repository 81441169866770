.hr-beneficiaries {
  display: flex;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.loading {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
  gap: 15px;
  span {
    font-size: 1rem;
  }
}
.loading-spinner {
  margin-bottom: 15px;
  color: var(--primary-color);
}

.hr-beneficiaries-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 80px);
}

#sentinela {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background: #fff;
}

#sentinela-loading {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background: #fff;
}

.hr-beneficiaries-consult {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 20px;
  width: 100%;

  &__title {
    font-family: 'Avenir Next GEO W05 Heavy';
    font-size: 20px;
    color: var(--color-grey);
  }
}

.hr-beneficiaries-filter {
  display: grid;
  grid-template-columns: 350px 1fr;
  column-gap: 25px;
  margin-bottom: 15px;
  width: max-content;
}

.hr-beneficiaries-filter-col-content {
  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 16px;
    color: var(--color-grey);
  }

  &__input-container {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 95%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }
  &__button {
    background-color: #02024E;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    height: 30px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}

.selectbox-first-row-container {
  display: grid;
}

.hr-beneficiaries-filter-checkbox-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;
    color: var(--color-grey);
  }
}

.hr-caroussel-container {
  display: flex;
  align-items: center;

  span {
    padding: 5px;
  }

  .caroussel-items-column {
    display: flex;
    max-width: 760px;
    overflow-x: hidden;
    margin-right: 8px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .caroussel-item {
    white-space: nowrap;
  }

  svg {
    margin-top: -2px;
    padding: 5px;
    &:hover {
      cursor: pointer;
      padding: 5px;
      background-color: #f0f0f0;
      border-radius: 17px;
    }
  }
}

.download-planos {
  padding-top: 10px;
}

.hr-beneficiaries-filter-radio-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;
    color: var(--color-grey);
  }
}

.hr-beneficiaries-table {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
}

.hr-beneficiaries-theader {
  display: grid;
  grid-template-columns: 20px 1.5fr 0.5fr 0.5fr 2.5fr 0.5fr 0.5fr 0.5fr 50px 50px 50px;
  align-items: center;
  border: 2px solid var(--color-tab-light-grey);
  box-sizing: border-box;
  padding: 5px;
  height: 40px;
  width: 100%;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  &__th {
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
    font-size: 16px;
  }
}

.hr-beneficiaries-tbody {
  display: grid;
  grid-template-columns: 20px 1.5fr 0.5fr 0.5fr 2.5fr 0.5fr 0.5fr 0.5fr 50px 50px 50px;
  background: #fff;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;

  &:hover {
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;
    background-color: var(--color-tab-light-grey);
  }

  &__td {
    text-align: left;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
  }

  &__td-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.hr-beneficiaries-tbody--expanded {
  display: grid;
  grid-template-columns: 20px 1.5fr 0.5fr 0.5fr 2.5fr 0.5fr 0.5fr 0.5fr 50px 50px 50px;
  background: #fff;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  border-bottom: none;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;

  &__td {
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
  }

  &__td-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.hr-beneficiaries-tbody-expanded {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 25px 10px 25px;
  border-top: 2px dashed var(--color-tab-light-grey);
  border-right: 2px solid var(--color-tab-light-grey);
  border-left: 2px solid var(--color-tab-light-grey);
  border-bottom: 2px solid var(--color-tab-light-grey);
  margin-bottom: 5px;

  &--animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
}

.hr-beneficiaries-tbody-expanded-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 10px;
}

.hr-beneficiaries-tbody-expanded-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;

  span {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;
    color: var(--color-grey);
  }

  &:first-child > .hr-expanded-content-icon {
    color: var(--color-dark-blue);
  }
  &:nth-child(2) > .hr-expanded-content-icon {
    color: var(--color-dark-blue);
  }
  &:last-child > .hr-expanded-content-icon {
    color: var(--color-dark-blue);
  }
}

.active-icon {
  margin-left: 5px;
  margin-right: 5px;
  color: var(--color-dark-blue);

  svg {
    width: 18px;
    height: 18px;
    fill: currentColor;
  }
}

.in-removal-icon {
  margin-left: 5px;
  color: #f8c000;

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}

.inactive-icon {
  margin-left: 5px;
  color: var(--color-disabled-grey);

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}

.calendar-icon {
  position: absolute;
  width: 10px; 
  height: 10px; 
  color: var(--primary-color);

  transform: translateX(-8px) translateY(8px);
}

.hover-display-info {
  position: absolute;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  z-index: 10;
  background-color: white;
  padding: 0px 10px 0px 10px;
  min-width: 150px;
  transform: translateX(calc(-65%)) translateY(calc(20% + 10px));
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.top-stripe {
  background-color: var(--color-dark-blue);
  width: calc(100% + 20px);
  transform: translateX(-10px) translateY(-1px);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  height: 4px;
}

.display-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-top: 4px;
  padding-bottom: 4px;

  .content-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    font-size: 12px;
    color: var(--primary-color);

    .building-icon {
      width: 15px;
      height: 15px;
      color: var(--color-dark-blue);
    }

    .card-icon {
      width: 14px;
      height: 14px;
      color: var(--color-dark-blue);

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.movimentacao-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  border-top: 1px solid var(--color-light-grey);
  padding-top: 8px;
  padding-bottom: 4px;
  font-size: 12px;
  row-gap: 8px;

  .movimentacao-content-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      font-family: "Avenir Next Geo W05 Demi";
    }
  }

  .movimentacao-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;

    .calendar {
      width: 13px;
      height: 13px;
      color: var(--color-dark-blue);

      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
}

.hover-display-info::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 70%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent var(--color-dark-blue) transparent;
}
