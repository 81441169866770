.select-field {
  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    color: var(--color-grey);
    margin-bottom: 10px;
    display: block;
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 2px solid var(--color-primary-blue);
    color: var(--color-grey);
    border-radius: 24px;
    padding: 12px;
    font-size: 16px;
    width: 100%;
    text-align: start;
    height: 48px;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 17px;

    &::placeholder {
      text-align: start;
      color: #c1c1c1;
    }

    &:focus-visible {
      outline-color: var(--color-primary-blue);
    }

    &--invalid {
      border-color: red;
    }

    &--error-message {
      color: red;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  &__group {
    position: relative;
    margin-bottom: 20px;

    &--invalid .field__input {
      border-color: red;
    }
  }
}
