.edit-table-header {
  display: grid;
  grid-template-columns: 30px 60px 1.5fr 0.75fr 0.5fr 1.5fr 0.5fr 0.5fr 80px;
  grid-gap: 10px;
  border: 2px solid var(--color-tab-light-grey);
  align-items: center;
  height: 42px;
  box-sizing: border-box;

  &__master-checkbox {
    padding-left: 6px;
    svg {
      height: 18px;
      width: 18px;
    }
  }

  &__button-edit {
    margin: 5px;
    margin-bottom: 0;
    margin-bottom: -1px;
    margin-left: 0;
    border-radius: 4px;
    color: var(--color-dark-blue);
  }

  &__checkbox {
    margin-left: -3px;
    margin-bottom: -2px;
    height: 30px;
    width: 25px;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  &__label {
    text-align: left;
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
  }

  &__icon-arrow {
    &:hover {
      cursor: pointer;
    }
  }
}

