.old-business {
  .business {
    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 100vh;
      background: #fff;

      max-width: calc(100% - 115px);
      overflow: auto;
      margin-left: 20px;
    }

    &__main {
      display: flex;
      flex-direction: column;
      row-gap: 35px;
      height: 100%;
      padding: 25px 0 25px 0;
      background: #fff;
    }

    &__wrapper {
      display: flex;

      .animeLeft {
        opacity: 0;
        transform: translateX(-20px);
        animation: animeLeft 0.3s forwards;
      }

      @keyframes animeLeft {
        to {
          opacity: 1;
          transform: initial;
        }
      }

      .MuiDrawer-paper {
        width: 550px;
        left: 'calc(50% - 275px)';
      }
    }
  }
}
