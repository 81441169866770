.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6edff;
  &__title {
    font-family: 'Avenir Next GEO W05 Heavy';
    color: var(--color-grey);
    font-size: 20px;
  }
  &__menu {
    cursor: pointer;
    color: var(--color-light-grey-alt);
  }
}

.header-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 40px;
}

.header-legend-container {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-top: 5px;
}

.header-legend {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;

  &__label-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1px;
  }

  &__dot-health {
    color: var(--color-dark-blue);
  }

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 18px;
    text-align: left;
    color: var(--color-grey);
  }

  &__data {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 18px;
    text-align: left;
    color: var(--color-grey);
  }

  &__dot-company {
    color: var(--color-dark-blue);
  }
}
