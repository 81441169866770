.hr-movs {
  display: flex;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.loading {
  display: flex;
  align-items: center;
  gap: 15px;
}

.hr-movs-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hr-movs-main {
  background: #fff;
  padding: 20px 45px;
  height: 100%;
  width: calc(100% - 90px);

  &--painel {
    max-width: calc(100vw - 170px);
  }
}

.hr-movs-consult {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 100%;

  h1 {
    font-family: 'Avenir Next GEO W05 Heavy';
    font-size: 20px;
    color: var(--color-grey);
  }
}

.hr-movs-filter {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr 30px 1fr;
  grid-gap: 30px;
  align-items: end;
  margin-bottom: 30px;
}

.hr-movs-filter-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 16px;
    color: var(--color-grey);
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    text-align: start;
    margin-top: 5px;
    height: 30px;
    width: 95%;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }

  &__radio-container {
    display: flex;
    flex-direction: row;
  }

  &__radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }
}

.hr-filter-movs-date-period {
  margin-bottom: 8px;
  width: max-content;

  &:hover {
    cursor: pointer;
  }
}

.hr-filter-movs-date-period-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 230px;

  &__radio-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__radio {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }
}

.hr-filter-movs-date-period-container-datepicker {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
  }

  &__input {
    border: 2px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    margin-left: 5px;
    height: 25px;
    width: 100px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }
  }
}

.hr-movs-filter-paper {
  padding: 10px;
}

.hr-movs-table-header {
  display: grid;
  grid-template-columns: 30px 60px 1.5fr 0.75fr 0.5fr 1.5fr 0.5fr 0.5fr 80px;
  grid-gap: 10px;
  border: 2px solid var(--color-tab-light-grey);
  align-items: center;
  height: 40px;
  box-sizing: border-box;

  &__label {
    text-align: left;
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
  }

  &__icon-arrow {
    &:hover {
      cursor: pointer;
    }
  }
}

.hr-movs-table-body {
  display: grid;
  grid-template-columns: 26px 60px 1.5fr 0.75fr 0.5fr 1.5fr 0.5fr 0.5fr 80px;
  grid-gap: 10px;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  align-items: center;
  height: 40px;
  box-sizing: border-box;

  &:hover {
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;
    background-color: var(--color-tab-light-grey);
  }

  &__label {
    text-align: left;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);

    &.truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 10px;
      display: block;
    }
  }

  &__icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    color: var(--color-dark-blue);
  }

  &__icon-visible {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
    color: var(--color-dark-blue);
    cursor: pointer;
  }
}

.hr-movs-table-body--expanded {
  display: grid;
  grid-template-columns: 26px 60px 1.5fr 0.75fr 0.5fr 1.5fr 0.5fr 0.5fr 80px;
  grid-gap: 10px;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  border-bottom: none;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
}

.hr-movs-table-body-mov-type {
  position: relative;
  z-index: 0;
  text-align: center;

  &[data-type="Inclusão"] svg {
    color: #43a047 !important;
  }
  
  &[data-type="Exclusão"] svg {
    color: #e53935 !important
  }

  &__legend {
    visibility: hidden;
    width: 80px;
    background-color: var(--primary-color);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    top: 100%;
    margin-left: -40px;
    z-index: 999;

    opacity: 0.8;
  }

  &:hover {
    .movs-table-body-mov-type__legend {
      visibility: visible;
    }
  }
}

.hr-mov-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 1rem 0;
  padding-left: 59px;

  border-top: 2px dashed var(--color-tab-light-grey);
  border-right: 2px solid var(--color-tab-light-grey);
  border-left: 2px solid var(--color-tab-light-grey);
  border-bottom: 2px solid var(--color-tab-light-grey);
}

.hr-mov-card-information {
  display: flex;
  flex-direction: column;

  div:first-child, div:nth-child(2) {
    display: flex;
    gap: 5px;
  }

  &__label {
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
  }

  &__span {
    text-align: center;
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
  }
}

.hr-mov-card-plans-information {
  &--align-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    row-gap: 10px;
  }
}

.hr-mov-card-plans-information-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 5px;
  justify-content: center;
  width: 100%;

  &--plan-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  &--plan-form {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__label {
    font-family: 'Avenir Next Geo W05 Demi';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__label-info {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__icon {
    color: var(--color-dark-blue);
    margin-right: 10px;
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    text-align: start;
    height: 25px;
    width: 200px;
    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    color: var(--color-grey);
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    margin-left: 5px;
    height: 25px;
    width: 200px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }

    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
    }
  }
}

.hr-text-center-movement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  color: var(--color-grey);
  font-size: 1.125rem;
  margin: 2rem 0;
  margin-top: 15vh;

  .circular-progress-loading {
    color: var(--color-light-blue-alt);
    margin-bottom: 30px;
  }
}
