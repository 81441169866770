.modal-dark-bg-new-task {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  -webkit-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  animation: fade-in 0.225s forwards;

  &::after {
    animation: fade-out 0.3s forwards;
  }

  .leave {
    animation: down-up 0.2s forwards;
  }
}

@keyframes fade-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fade-out {
  from {
    background-color: rgba(0, 0, 0, 0.7);
  }
  to {
    background-color: transparent;
  }
}

@keyframes down-up {
  from {
    transform: translateY(calc(50% - 200px));
  }
  to {
    transform: translateY(-100%);
  }
}

.new-task {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.new-task-content {
  background: #fff;
  border: 1px solid var(--color-tab-light-grey);
  border-radius: 10px;
  height: 510px;
  width: 510px;
}

.new-task-content-header {
  background: var(--color-tab-light-grey);
  border-radius: 10px;
  padding: 15px 15px 20px 20px;

  &__title {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 18px;
    color: var(--color-grey);
  }
}

.new-task-content-form-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.new-task-content-first-form-option {
  display: flex;
  flex-direction: row;
  column-gap: 20px;

  &__label {
    color: var(--color-grey);
    font-weight: 500;
    font-size: 14px;
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    margin-top: 7px;
    width: 350px;
    text-align: start;
    height: 32px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }
}

.new-task-content-second-form-option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &__textarea-container {
    width: 100%;
  }

  &__label {
    color: var(--color-grey);
    font-weight: 500;
    font-size: 14px;
  }

  &__text-area {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 5px;
    padding: 15px;
    margin-top: 7px;
    height: 40px;
    width: 430px;
    overflow-y: auto;

    &:focus-visible {
      outline: none;
    }

    &::-webkit-scrollbar {
      width: 3px;
      padding: 15px 0px;
      background: rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--color-tab-dark-grey);
      padding: 15px 0px;
      box-shadow: none;
      
    }
    &::-webkit-scrollbar-track {
      margin-top: 15px;
      margin-bottom: 15px;
      box-shadow: inset 0 0 2px rgb(0, 0 , 0, 0);
    }
  }
}

.new-task-content-third-form-option {
  &__clint-type-container {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }

  &__radio-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;

    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }

  &__label {
    color: var(--color-grey);
    font-weight: 500;
    font-size: 14px;
  }

  &__select {
    width: 272px;
  }
}

.new-task-content-fourth-form-option {
  &__product-type-container {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }

  &__radio-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;

    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }

  &__label {
    color: var(--color-grey);
    font-weight: 500;
    font-size: 14px;
  }

  &__select {
    width: 220px;
  }
}

.new-task-content-fifth-form-option {
  display: flex;
  flex-direction: row;
  column-gap: 15px;

  &__label {
    color: var(--color-grey);
    font-weight: 500;
    font-size: 14px;
  }

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 33px;
    width: 100px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }
  }
}

.new-task-content-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 10px;
  padding-top: 10px;
}
