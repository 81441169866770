.ticket-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  background: #fff;
  border: 2px solid var(--color-tab-light-grey);
  padding: 35px 40px;
  box-sizing: border-box;
  height: 220px;
  width: 100%;
}

.ticket-card-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;

  &__fire-icon {
    position: absolute;
    transform: translate(250px, -17px);
    height: 30px;
    width: 30px;
  }
}

.ticket-card-container-content {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  &__icon {
    color: var(--color-dark-blue);
  }

  &__text {
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__edit-text {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--icon-check {
      color: var(--secondary-color);
    }
  }

  &__button-details {
    padding-left: 30px;
  }

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 95%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 14px;
    }
  }

  .select-width {
    width: 35%;
  }

  &__input-date {
    border: none;
    color: var(--color-grey);
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    margin-left: 5px;
    height: 25px;
    width: 150px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }

    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 14px;
      color: var(--color-grey);
    }
  }
}

.ticket-card-container-menu {
  position: relative;
  display: inline-block;

  span {
    display: block;
    padding: 10px;
    cursor: pointer;

    p {
      margin: 0;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      color: var(--primary-color);
    }
  }

  .drop-down {
    display: none; /* Esconde o menu drop-down por padrão */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    min-width: 190px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        a {
          display: block;
          padding: 12px 16px;
          text-decoration: none;
          font-family: 'Avenir Next GEO W05 Regular';
          font-size: 16px;
          color: var(--color-grey);

          &:hover {
            background-color: var(--color-tab-dark-grey);
            font-family: 'Avenir Next GEO W05 Demi';
            color: var(--primary-color);
          }
        }
      }
    }
  }

  &:hover .drop-down {
    display: block; /* Mostra o menu drop-down ao passar o mouse */
  }
}

.ticket-card-container-search {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  column-gap: 15px;

  label {
    position: absolute;
    top: -10px;
    left: 18px;
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 16px;
    color: var(--primary-color);
    font-size: 14px;
    background: #fff;
    letter-spacing: 0.4px;
  }

  input {
    border: none;
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    color: var(--color-grey);
    font-size: 16px;
    text-align: start;
    height: 30px;

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }
}
