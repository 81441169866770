.hr-step {
  width: 450px;

  .hr-step-corretion-request {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &__button-request-container {
      padding: 5px;
    }
  }

  .hr-step-doc-list {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .hr-step-doc-list-title {
      margin-bottom: 10px;

      &__span {
        font-family: 'Avenir Next GEO W05 Demi';
        font-size: 16px;
      }
    }

    &__box {
      border: 1px solid var(--color-light-grey-alt);
      border-radius: 6px;
      padding: 12px;
      height: 140px;
      width: 97%;
      overflow-y: auto;
    }

    &__link {
      background: transparent;
      border: none;
      display: flex;
      gap: 4px;
      padding: 4px 8px;
      color: blue;

      font-size: 16px;
      cursor: pointer;
      transition: all 0.4s;
      font-weight: 600;
      letter-spacing: normal;
    }
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-top: 30px;
  }
}
