.tasks {
  display: flex;
  height: 100vh;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }

  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }

  .MuiDrawer-paper {
    width: 550px;
    left: calc(50% - 275px);
  }
}

.tasks-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fff;

  &__main {
    box-sizing: border-box;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}