.financial-graphic {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 300px;
  width: 700px;
  box-sizing: border-box;

  &__title {
    font-family: 'Avenir Next GEO W05 Bold';
    color: var(--color-genoa-dark-blue);
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
  }
}

.recharts-legend-item-text {
  font-family: 'Avenir Next GEO W05 Regular';
  font-size: 12px;
}

.financial-graphic-tooltip {
  background-color: var(--color-white);
  height: 60px;
  width: 190px;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    padding: 5px;
    background-color: var(--color-white);

    &--lifetime {
      border: 1px solid var(--color-primary-blue);
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }

    &--agency {
      border: 1px solid var(--color-dark-blue);
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }
  }

  &__title {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 14px;
    color: var(--color-grey);
    text-align: center;
  }

  &__data-row {
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
    padding: 5px 5px 3px 5px;
  }

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 13px;
    color: var(--color-grey);
    text-align: left;
  }

  &__value {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 12px;
    color: var(--color-grey);
    text-align: left;
  }
}