.tasks-filter {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}

.tasks-filter-content {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 13px;
  }

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 100%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 12px;
    }
  }
}

.tasks-filter-radio-container {
  display: flex;
  flex-direction: row;

  .MuiSvgIcon-root {
    font-size: 14px;
  }
}

.tasks-filter-content-option-periodo {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.tasks-filter-datepicker-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-top: 8px;
  height: 60px;
  width: 230px;
}

.tasks-filter-datepicker-content {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  &__input-date {
    border: 2px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 25px;
    width: 100px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }
  }
}
