.broker-financial {
  display: flex;
  height: 118vh;
}

.broker-financial-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 105vh;
}

.broker-financial-content {
  display: flex;
  flex-direction: column;
}

.broker-financial-horizontal-bar {
  background-color: #e6edff;
  margin-top: 40px;
  margin-bottom: 40px;
  height: 1px;
}