.inclusion-form {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-light-grey);

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--color-tab-light-grey);
    border: 1px solid var(--color-border-grey);
    padding: 20px 30px;
  }

  &__main {
    box-sizing: border-box;
    padding: 70px 20px 0px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.inclusion-form-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 20px;
  margin-bottom: 30px;

  &__icon {
    height: 30px;
    width: 30px;
  }

  &__text {
    h2 {
      font-family: 'Avenir Next GEO W05 Bold';
    }
  }

  &__progress-container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 15px;
    width: 450px;
  }

  &__progress-legend-container {
    padding-left: 90%;
  }

  &__steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    margin-bottom: 30px;
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-top: 20px;
  }
}

.inclusion-form-message {
  padding: 25px;

  &__span {
    font-family: 'Avenir Next GEO W05 Bold';
    font-size: 18px;
    color: red;
  }
}

.form-fill__button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #ed4545 !important;
}
