.input-standard {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    color: var(--color-grey);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;

    &--disabled {
      color: var(--color-tab-light-grey);
    }
  }

  &__input {
    border: none;
    font-size: 14px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 7px;

    &:hover {
      border-color: var(--color-tab-dark-grey);
    }
  }
}
