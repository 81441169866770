.select-standard {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    color: var(--color-grey);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;

    &--disabled {
      color: var(--color-tab-light-grey);
    }
  }

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 6px;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      border-color: var(--color-tab-dark-grey);
    }

    &--disabled {
      cursor: not-allowed;
      border-color: var(--color-tab-light-grey);
    }
  }

  &__value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  &__icon {
    transition: transform 0.3s ease;

    &--open {
      transform: rotate(180deg);
    }
  }

  &__popper {
    z-index: 2000;
  }

  &__options-wrapper {
    z-index: 2000;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 2px;
    border-top: none;
    border-radius: 4px;
    padding: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-primary-blue);
      border-radius: 10px;
    }
  }

  &__option {
    padding: 6px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
      background-color: var(--color-primary-blue);
      color: #fff;
    }

    &--focused {
      background-color: var(--color-primary-blue);
      color: #fff;
    }
  }
}
