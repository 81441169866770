.ds-input-file {
  &__label {
    &--transparent {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      color: rgb(30, 37, 82);
      padding: 5px 10px;
      cursor: pointer;
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }

    &--broker {
      display: inline-block;
      font-family: 'Avenir Next GEO W05 Demi';
      padding: 6px 12px;
      border: none;
      border-radius: 5px;
      gap: 6px;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      color: #fff;
      background: var(--color-primary-blue);

      &:hover:not(.ds-button--disabled) {
        background: var(--color-primary-blue-hover);
      }
    }

    &--disabled {
      display: inline-block;
      font-family: 'Avenir Next GEO W05 Demi';
      background: var(--color-disabled-grey);
      color: var(--color-subtle-grey);
      padding: 6px 12px;
      border: none;
      border-radius: 5px;
      gap: 6px;
      align-items: center;
      cursor: not-allowed;
      pointer-events: none;
  
      &:hover {
        background: var(--color-disabled-grey);
      }
    }
  }

  &__input {
    display: none;
  }
}