.tickets-initial-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 80px;
  padding-left: 20px;
  padding-bottom: 40px;
  box-sizing: border-box;

  &__button-container {
    margin-top: 30px;
    &--icon {
      color: #fff;
    }
  }
}

.tickets-initial-filter-search {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 51px;

  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    text-align: start;
    height: 25px;
    width: 100%;
    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }
}

.tickets-initial-filter-content {
  display: flex;
  flex-direction: column;
  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__radio-group, .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    &--radio {
      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
  }

  &__datepicker-box-space {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 51px;

    &--group-container {
      display: grid;
      grid-template-columns: 125px 125px;
      margin-top: 3px;
      overflow: hidden;
      transition: all .6s cubic-bezier(0.4, 0, 0.2, 1);
    }
  
    &--group-container-hidden {
      display: grid;
      grid-template-columns: 125px 125px;
      margin-top: 3px;
      overflow: hidden;
      transition: all .6s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    color: var(--color-grey);
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    margin-left: 5px;
    height: 25px;
    width: 100px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }

    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
    }
  }

  &__datepicker-selector-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
}

.tickets-filter {
  margin-top: 25px;
}

.tickets-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
  align-items: center;
  box-sizing: border-box;

  &__order-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 3px;
    cursor: pointer;
  }
}

.tickets-filter-container-select {
  display: flex;
  flex-direction: row;
  column-gap: 70px;

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    text-align: start;
    margin-top: 5px;
    height: 30px;
    width: 100%;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    &--menu-item {
      font-size: 14px;
    }
  }
}

.tickets-filter-container-select-datepicker {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-top: 10px;

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    color: var(--color-grey);
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    margin-left: 5px;
    height: 25px;
    width: 200px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }

    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
    }
  }
}
