.select-checkbox-component {
  display: flex;
  flex-direction: column;

  &__label {
    color: var(--color-grey);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &--disabled {
    color: var(--color-tab-light-grey);
  }

  .select-trigger {
    font-size: 14px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 6px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:focus {
      outline: none;
    }

    &:hover {
      border-color: var(--color-tab-dark-grey);
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }

    &__icon,
    &__custom-icon {
      transition: transform 0.3s;
    }

    &__custom-icon {
      margin-right: 2px;
      font-size: 20px;
    }

    &__icon--open {
      transform: rotate(180deg);
    }
  }

  .disabled {
    cursor: not-allowed;
    border-color: var(--color-tab-light-grey);
  }

  .popper-container {
    z-index: 2000;
  }

  &__options-wrapper {
    z-index: 2000;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 2px;
    border-top: none;
    border-radius: 4px;
    padding: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 200px;

    &--broker-options {
      &::-webkit-scrollbar {
        width: 4px;
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--color-primary-blue);
        border-radius: 10px;
      }
    }
  }

  .option {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;

    &:hover {
      background-color: var(--primary-color);
      color: #fff;
      .option__checkbox.Mui-checked {
        color: #fff !important;
      }
    }

    &__checkbox {
      margin-right: 8px;
      color: var(--color-light-grey-alt);

      &.Mui-checked {
        color: var(--primary-color);
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}
