.hr-contracts {
  display: flex;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.loading {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
  gap: 15px;
  span {
    font-size: 1rem;
  }
}

.loading-spinner {
  margin-bottom: 15px;
  color: var(--color-light-blue-alt);
}

.hr-contracts-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 80px); 
}

.hr-contracts-content-title {
  margin-right: 10px;
  margin-bottom: 30px;
  width: 100%;

  h1 {
    font-family: "Avenir Next GEO W05 Bold";
    font-size: 20px;
    color: var(--color-grey);
  }
}

.hr-contracts-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 40px;

  &__label{
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    color: var(--color-grey);
  }
}

.hr-contracts-filter-radio-group {
  display: flex;
  flex-direction: row;

  .MuiSvgIcon-root {
    font-size: 18px;
  }
}

.hr-contracts-table {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  margin-top: 30px;
  box-sizing: border-box;

  &__thead-tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    border: 2px solid var(--color-tab-light-grey);
    padding-top: 5px;
    padding-bottom: 10px;
    box-sizing: border-box;
    height: 33px;
    width: 100%;

    th:first-child {
      text-align: left;
      padding-left: 15px;
    }
  }

  &__thead-th {
    text-align: center;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    color: var(--color-grey);
  }

  &__tbody-tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    column-gap: 20px;
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;
    padding-bottom: 5px;
    box-sizing: border-box;
    height: 40px;
    width: 100%;

    &:hover {
      border: 2px solid var(--color-tab-light-grey);
      border-top: none;
      background-color: var(--color-tab-light-grey);
    }

    td:first-child {
      text-align: left;
      padding-left: 15px;
    }

    td:last-child {
      display: flex;
      justify-content: center;
    }
  }

  &__tbody-td {
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 16px;
    color: var(--color-grey);
  }

  &--active-icon {
    display: flex;
    justify-content: center;
     
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    color: var(--color-dark-blue);

    svg {
      width: 17px; 
      height: 17px; 
      fill: currentColor;
    }
  }
}

.status-in-cancel-process-circle-icon {
  animation: blinking-in-cancel-process-icon 1.5s infinite;
}

.status-canceled-active-circle-icon {
  animation: blinking-canceled-icon 1.5s infinite;
}

.status-active-circle-icon>circle {
  animation: blinking-active-icon 1.5s infinite;
}

@keyframes blinking-active-icon {
  0% { color: #008631; }
  50% { color: #00C04B; }
  100% { color: #008631; }
}

@keyframes blinking-in-cancel-process-icon {
  0% { color: #D29E00; }
  50% { color: #FFD900; }
  100% { color: #D29E00; }
}

@keyframes blinking-canceled-icon {
  0% { color: #8B0000; }
  50% { color: #DC143C; }
  100% { color: #8B0000; }
}
