.rh-billing {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-left: 65px;
  padding-right: 65px;
}

.rh-billing-filter {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    color: var(--color-subtle-grey);
  }

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 2px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 140px;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 14px;
    }
  }
}

.rh-billing-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__theader-tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border: 2px solid var(--color-tab-light-grey);
    border-bottom: none;

    th:last-child {
      text-align: left;
      font-size: 16px;
      font-family: 'Avenir Next GEO W05 Demi';
      color: var(--color-grey);
      padding: 15px 15px 10px 15px;
    }
  }

  &__theader-th {
    text-align: left;
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
    border-right: 2px solid var(--color-tab-light-grey);
    padding: 15px 15px 10px 15px;
  }

  &__tbody-tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border: 2px solid var(--color-tab-light-grey);

    td:last-child {
      padding: 15px 15px 10px 35px;
    }
  }

  &__tbody-td {
    text-align: left;
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    border-right: 2px solid var(--color-tab-light-grey);
    padding: 15px 15px 10px 15px;
  }
}