.broker-sidebar {
  display: flex;
  flex-direction: column;
  background-color: var(--color-genoa-dark-blue);
  width: 80px;
}

.broker-sidebar-nav-fixed {
  display: inherit;
  flex-direction: inherit;
  background-color: var(--color-genoa-dark-blue);
  width: inherit;

  .sidebar-anime-left {
    margin-top: 30px;
    opacity: 1;
  }

  @media (max-height: 580px) {
    display: inherit;
    flex-direction: inherit;
    position: absolute;
    background-color: var(--color-genoa-dark-blue);
    height: 850px;
    width: inherit;
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: inherit;
    flex-direction: inherit;
    position: absolute;
    background-color: var(--color-genoa-dark-blue);
    min-height: 850px;
    max-height: 920px;
    width: inherit;
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: inherit;
    flex-direction: inherit;
    position: absolute;
    background-color: var(--color-genoa-dark-blue);
    min-height: 920px;
    max-height: 990px;
    width: inherit;
  }

  &__icon-container {
    display: flex;
    height: 44px;
    width: 29px;

    &:hover {
      svg {
        font-size: 20px;
      }
    }
  }

  &__description {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: #fff;
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;
    background-color: var(--color-dark-gray);
    padding: 3px 5px;
    top: 2px;
    left: 85px;
    opacity: 0.8;
    transition: opacity 0.5s;
    border-radius: 5px;
    height: max-content;
    width: max-content;

    &--off {
      opacity: 0;
      pointer-events: none;
    }
  }

  &--invisible {
    .MuiBadge-badge {
      background-color: var(--color-pink);
      transform: scale(0) translate(70%, 45%);
    }
  }

  &--business-without-owner {
    position: relative;
    transform: translateY(-10px) translateX(30px);

    .MuiBadge-badge {
      background-color: #FFD600;
      color: #FFFFFF;
      font-weight: bold;
    }

    .activities-notification {
      transform: translateY(-4px) translateX(5px);
    }
  }

  ul {
    list-style: none;

    li.broker-sidebar-logo {
      margin-bottom: 40px;

      img {
        width: 25px;
      }
    }

    li .money-icon {
      background-color: #fff;
      color: var(--color-genoa-dark-blue);
      border-radius: 35px;

      path:nth-child(2) {
        transform: translateX(0.5px);
      }
    }

    li {
      position: relative;
      font-size: 25px;
      text-align: center;
      margin-bottom: 25px;

      svg {
        color: #fff;
        transition: 0.2s;

        &:hover {
          font-size: 30px;
        }
      }

      img {
        transition: 0.2s;

        &:hover {
          height: 40px;
          width: 40px;
        }
      }

      a {
        display: flex;
        justify-content: center;
        height: 35px;
      }
    }
  }
}

.MuiBadge-badge {
  transform: scale(1) translate(70%, 45%);
  background-color: var(--color-pink);
  z-index: 1000;
  text-align: center;
  font-size: 10px;
  padding: 0 6px;
  height: 18px;
  min-width: 18px;
}