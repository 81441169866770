.incoming-information {
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;

  &__header {
    margin-bottom: 10px;

    &--title {
      font-family: 'Avenir Next Geo W05 Bold';
      color: var(--color-genoa-dark-blue);
      font-size: 20px;
      text-align: left;
    }
  }
}

.incoming-information-filter {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__period {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 16px;
    color: var(--color-grey);
  }

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 75%;
    text-align: start;
    height: 30px;

    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 14px;
    }
  }

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    color: var(--color-grey);
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 30px;
    width: 95%;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }

    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-subtle-grey);
      font-size: 14px;
    }
  }

  &__radio-group {
    display: flex;
    flex-direction: row;

    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 16px;
    color: var(--color-grey);

    .MuiFormGroup-root {
      flex-direction: row;
    }

    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }
}

.incoming-table {
  width: 100%;
}

.incoming-table-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  &__theader-tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: 1px solid var(--color-tab-light-grey);

    th:first-child {
      text-align: left;
    }

    th:last-child {
      border-right: none;
    }
  }

  &__theader-th {
    text-align: center;
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
    border-right: 1px solid var(--color-tab-light-grey);
    padding: 10px 5px 5px 5px;
  }

  &__tbody-tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: 1px solid var(--color-tab-light-grey);
    border-top: none;

    td:first-child {
      text-align: left;
    }

    td:last-child {
      border-right: none;
    }

    &:hover {
      border: 2px solid var(--color-tab-light-grey);
      border-top: none;
      background-color: var(--color-tab-light-grey);
    }
  }


  &__title-pop-up {
    display: flex;
    position: absolute;
    align-items: center;
    background-color: #c1c1c1;
    padding: 5px;
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);

    height: 30px;
    max-width: 500px;
  }

  &__status-pop-up {
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);

    height: 30px;
    width: 60px;

    &--active {
      border: 1px solid var(--color-hover-green);
    }

    &--inactive {
      border: 1px solid var(--color-pink);
    }
  }

  &__tbody-td {
    text-align: center;
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    border-right: 1px solid var(--color-tab-light-grey);
    padding: 8px 5px 5px 5px;
    height: 30px;

    &.td-truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 10px;
      display: block;
    }
  }
}