.tasks-table {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &__theader-tr {
    display: grid;
    grid-template-columns: 80px 1fr 80px 100px 1fr 0.5fr 0.5fr 0.5fr 100px 100px;
    border: 2px solid var(--color-tab-light-grey);

    th:first-child {
      text-align: center;
    }

    th:last-child {
      text-align: left;
      font-size: 0.85em;
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
      padding: 7px 5px 8px 5px;
    }
  }

  &__theader-th {
    text-align: left;
    font-size: 0.85em;
    font-family: 'Avenir Next GEO W05 Regular';
    border-right: 2px solid var(--color-tab-light-grey);
    padding: 7px 5px 8px 5px;
  }

  &__tbody {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
}
