.ds-textarea {
  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    color: var(--color-grey);
    margin-bottom: 10px;
    display: block;
  }

  &__textarea {
    background-color: #fff;
    border: 2px solid var(--color-primary-blue);
    border-radius: 24px;
    padding: 12px;
    box-sizing: border-box;
    opacity: 1;
    font-size: 16px;
    color: var(--color-grey);
    font-family: 'Avenir Next GEO W05 Regular';
    width: 100%;

    &::placeholder {
      text-align: start;
      color: var(--color-disabled-grey);
    }

    &:focus-visible {
      outline-color: var(--color-primary-blue);
    }

    &--disabled {
      background-color: var(--color-tab-light-grey);
      border: 2px solid var(--color-disabled-grey);
      cursor: not-allowed;

      &:focus-visible {
        outline-color: var(--color-disabled-grey);
      }
    }
  }
}
