.drawer-nova-atividade {
  border-left: 10px solid var(--primary-color);
  border-radius: 10px;
  background-color: #fff;
  width: 550px;
  position: fixed;
  left: calc(60% - 305px);
  padding: 25px;
  z-index: 10000;

  display: flex;
  flex-direction: column;
  row-gap: 30px;

  &__title {
    font-family: 'Avenir Next Geo W05 Heavy';
    font-size: 1.2em;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  &__form-option {
    display: flex;
    flex-direction: column;

    .status {
      background: #f4f4f4;
      color: black !important;
      border-radius: 10px;
      padding: 2px 10px;
      font-size: 10px;
      margin-left: 5px;
    }
  }

  &__form-option-title {
    font-family: 'Avenir Next Geo W05 Demi';
    font-size: 0.85em;
  }

  &__custom-datepicker {
    border: none;
    border-bottom: 1px solid var(--primary-color);
    display: inline-block;
    overflow: hidden;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 22px;
    padding-bottom: 6px;
    width: 90px;
    margin-top: 15px;

    &.active {
      border-bottom: 2px solid var(--primary-color);
    }

    .react-datepicker__day--selected:hover {
      color: white;
      background-color: var(--color-primary-blue);
      font-weight: bold;
    }

    .react-datepicker__day--selected {
      color: white;
      background-color: var(--primary-color);
      font-weight: bold;
    }

    .react-datepicker__day--keyboard-selected:hover {
      color: var(--primary-color);
      background-color: #f0f0f0;
    }

    .react-datepicker__day--keyboard-selected {
      color: var(--primary-color);
      background-color: white;
      font-weight: bold;
    }
  }

  &__textarea-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    textarea {
      resize: none;
      padding: 10px;
      border: 1px solid var(--primary-color);
      margin-bottom: 10px;
      border-radius: 12px;
      font-family: 'Avenir Next Geo W05 Regular';
      width: 300px;

      :focus-visible {
        outline: 1px var(--primary-color) auto;
      }

      ::-webkit-scrollbar {
        width: 3px;
        padding: 16px 0px;
        background: rgba(0, 0, 0, 0);
      }

      ::-webkit-scrollbar-thumb {
        background: #e7e7e7;
        padding: 16px 0px;
        box-shadow: none;
      }

      ::-webkit-scrollbar-track {
        margin-top: 16px;
        margin-bottom: 16px;
        box-shadow: inset 0 0 2px rgb(0, 0, 0, 0);
      }
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__criar-btn {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: var(--primary-color);
    color: white;
    padding: 4px 8px;
    font-family: 'Avenir Next Geo W05 Demi';
    text-transform: uppercase;
    font-size: 0.9em;
    border-radius: 5px;
    top: 0;
    right: 0;

    &.disabled {
      background-color: #a1a1a1;
    }
  }
}
