.modal-logs {
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 10;
  background-color: white;
  padding: 15px 10px 15px 10px;
  transform: translateX(calc(-19%)) translateY(calc(-60%));
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  min-height: 90px;
  max-height: 200px;
  min-width: 150px;

  overflow-y: auto;
  overflow-x: hidden;

  &__top-stripe {
    background-color: var(--color-dark-blue);
    transform: translateX(-10px) translateY(-1px);
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    height: 4px;
    width: calc(100% + 20px);
  }

  &__item {
    margin-bottom: 10px;
  }

  &__name {
    font-family: 'Avenir Next GEO W05 Regular';
    margin-right: 10px;
    font-size: 16px;
    font-weight: 700;
  }

  &__date {
    font-size: 14px;
    color: #323232;
  }
}
