.table-exclusion-schedule {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  &__thead-tr {
    display: grid;
    grid-template-columns: 220px 200px 1fr 1fr 150px 160px 1fr 1fr 0.5fr;
    border: 2px solid var(--color-tab-light-grey);

    th:first-child {
      padding-left: 20px;
    }

    th {
      display: flex;
      align-items: center;
      text-align: left;
      font-family: 'Avenir Next GEO W05 Demi';
      color: var(--color-grey);
      font-size: 16px;
      padding-left: 5px;
      padding-right: 5px;
      height: 40px;
    }

    &--modal-delete {
      position: absolute;
      z-index: 1;
      transform: translateX(-500px);
    }
  }

  &__tbody-tr {
    display: grid;
    grid-template-columns: 220px 200px 1fr 1fr 150px 160px 1fr 1fr 0.5fr;
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;

    td:first-child {
      padding-left: 20px;
    }

    td:last-child {
      padding-left: 20px;
    }

    td {
      display: flex;
      align-items: center;
      text-align: left;
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
      font-size: 14px;
      padding-left: 5px;
      padding-right: 5px;
      height: 40px;
    }
  }

  &__tbody-td-icon {
    margin-left: 40px;
  }

  &__icon {
    color: var(--color-subtle-grey);

    &--checked {
      color: var(--color-hover-green);
    }
  }

  &__tbody-input-date {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      padding-top: 10px;
    }
  }

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 20px;
    width: 80%;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }
  }

  &__tbody-pop-up {
    display: flex;
    position: absolute;
    align-items: center;
    background-color: #c1c1c1;
    padding: 5px;
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);

    height: 14px;
    max-width: 500px;
  }
}
