.hr-change-password {
  display: flex;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.hr-change-password-content {
  width: calc(100% - 80px);
}

.hr-change-password-consult {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 100%;

    span {
      font-family: 'Avenir Next GEO W05 Bold';
      font-size: 18px;
      color: var(--color-grey);
    }
  }
}

.hr-change-password-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.hr-change-password-inputs-container {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding-left: 5px;

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: 62px;

    .input-icon {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 50px;
    }
  }

  &__label {
    text-align: left;
    font-size: 16px;
    font-family: "Avenir Next GEO W05 Regular";
    color: var(--color-grey);
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 150%;
    text-align: start;
    height: 35px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }

  &__icon {
    color: var(--color-dark-blue);
    padding: 5px;
  }
}

.error-message-span {
  font-family: "Avenir Next GEO W05 Demi";
  padding-left: 15px;
  color: red;
  font-size: 15px;
}

.confirmation-message-span {
  font-family: "Avenir Next GEO W05 Demi";
  padding-left: 15px;
  color: green;
  font-size: 15px;
}

.hr-change-pass-veritical-bar {
  background-color: #e6edffce;
  height: 400px;
  width: 2px;
}

.settings-emails {
  .settings-emails-notifications-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }
  }
}

.settings-emails-notifications-list-informative-mark {
  &__icon-question {
    color: var(--color-subtle-grey);
  }

  &__information-pop-up {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    margin-left: -180px;
    width: 350px; // ajuste conforme necessário
  
    span {
      color: #333;
      font-size: 14px;
    }
  }
}
