.ds-tabs {
  &__tabs {
    width: 100%;
    border-bottom: 1px solid EBEBEB;
    min-height: 40px !important;

    .MuiTab-root {
      min-width: 0;
      padding: 0 20px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      text-transform: capitalize;
      font-weight: 400;
      min-height: 38px !important;
    }

    .Mui-selected {
      color: var(--color-text) !important;
      font-weight: 700;
    }

    .MuiTab-textColorPrimary {
      text-transform: capitalize !important;
      font-size: 16px;
      min-height: initial;
    }

    .MuiTabs-indicator {
      background-color: var(--color-text);
    }
  }
}
