.modal-dark-bg-contract-details {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  -webkit-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  animation: fade-in 0.225s forwards;

  &::after {
    animation: fade-out 0.3s forwards;
  }

  .leave {
    animation: down-up 0.2s forwards;
  }
}

@keyframes fade-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fade-out {
  from {
    background-color: rgba(0, 0, 0, 0.7);
  }
  to {
    background-color: transparent;
  }
}

@keyframes down-up {
  from {
    transform: translateY(calc(50% - 200px));
  }
  to {
    transform: translateY(-100%);
  }
}

.modal-contract-details {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.contract-details {
  background: var(--color-tab-light-grey);
  border-radius: 4px;
  height: 400px;
  max-height: 450px;
  width: 550px;

  overflow-y: auto;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 20px 20px;
  }
}

.contract-details-content {
  margin: 0px 20px 15px 20px;
  padding: 10px 15px 10px 15px;
}

.contract-details-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 15px;
    color: var(--color-grey);
  }

  &__text {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 16px;
    color: var(--color-dark-gray);
  }
}

.contract-details-label-border {
  border: 3px solid var(--color-tab-dark-grey);
  padding: 5px;
  width: 30%;
}

.contract-details-item-border {
  border: 3px solid var(--color-tab-dark-grey);
  padding: 5px;
  width: 60%;
}
