.tasks-details {
  width: 900px;
}

.tasks-details-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  padding: 20px;
  padding-left: 50px;
  background-color: var(--primary-color);
  height: 100px;
  color: #fff;

  &__title {
    font-family: 'Avenir Next Geo W05 Bold';
    font-size: 19px;
  }
  &__subtitle {
    font-family: 'Avenir Next Geo W05 Medium';
    font-size: 15px;
  }
}

.tasks-details-header-back {
  padding: 5px 0px 0px 0px;
  &__icon-back {
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    svg {
      font-size: 14px;
    }
  }
}

.tasks-details-content {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.tasks-details-content-first-line {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
}

.tasks-details-content-cols {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

  &__label{
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 16px;
    color: var(--color-subtle-grey);
  }

  &__text {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;
    color: var(--color-grey);
  }

  &__date-input {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 25px;
    width: 100px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }
  }

  &__text-area-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__text-area {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    padding: 15px;
    height: 60px;
    width: 96%;
    overflow-y: auto;

    &:focus-visible {
      outline: none;
    }

    &::-webkit-scrollbar {
      width: 3px;
      padding: 15px 0px;
      background: rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--color-tab-dark-grey);
      padding: 15px 0px;
      box-shadow: none;
      
    }
    &::-webkit-scrollbar-track {
      margin-top: 15px;
      margin-bottom: 15px;
      box-shadow: inset 0 0 2px rgb(0, 0 , 0, 0);
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
}

.tasks-details-content-second-line {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.tasks-details-content-third-line {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-tab-dark-grey);
  border-radius: 6px;

  &__cols {
    display: flex;
    flex-direction: row;

    span:first-child {
      border-right: 1px solid var(--color-tab-dark-grey);
      border-bottom: 1px solid var(--color-tab-dark-grey);
      padding: 5px;
      width: 20%;
    }

    span:last-child {
      border-bottom: 1px solid var(--color-tab-dark-grey);
      padding: 5px;
      width: 80%;
    }
  }
}

.tasks-details-content-fourth-line {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__cols {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  ul {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    width: 70%;
  }

  &__list-container {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    border: 1px solid var(--color-tab-dark-grey);
    width: 100%;

    div:first-child {
      align-self: center;
      text-align: center;
      border-right: 1px solid var(--color-tab-dark-grey);
      padding: 4px;
    }
    div:nth-last-child(2) {
      align-self: center;
      text-align: left;
      padding: 4px;
    }
    div:last-child {
      align-self: center;
      text-align: center;
      border-left: 1px solid var(--color-tab-dark-grey);
      padding: 4px;
    }
  }

  &--checked {
    text-decoration: line-through;
  }
}

.tasks-details-content-fourth-line-checklist-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  width: 75%;

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 85%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &__icon {
    height: 18px;
    width: 18px;
  }
}