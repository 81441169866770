.text-editor {
  margin-bottom: 100px;
}

.text-editor-file {
  margin-top: 10px;
  max-height: 76px;
  overflow-y: auto;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
  }

  &__link {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 12px;
    margin-left: 5px;
  }

  &__remove-button {
    background: none;
    border: none;
  }
}

.editor {
  border: 1px solid var(--color-grey);
  border-radius: 4px;
  &__textarea {
    padding-left: 5px;
    font-family: sans-serif;
    overflow-y: auto;
    height: 100px;
  }
}
