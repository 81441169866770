.filter-negocios {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 10px;
  padding-right: 30px;

  &__type-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 200px;
  }
}
