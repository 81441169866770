.tickets-companies {
  display: block;
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  &__title {
    font-size: 20px;
    font-family: 'Avenir Next GEO W05 Bold';
    color: var(--color-grey);
  }
}

.tickets-companies-table {
  background-color: none;
  border: 2px solid var(--color-border-grey);
  border-bottom: none;
  border-radius: 6px;
  margin-top: 5px;
  overflow-y: hidden;

  &__theader {
    display: block;
    text-align: left;
    position: sticky;
  }

  &__theader-tr {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    border-bottom: 2px solid var(--color-border-grey);

    th:last-child {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
      font-size: 14px;
      padding: 5px;
    }
  }

  &__theader-th {
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    text-align: start;
    border-right: 2px solid var(--color-border-grey);
    font-size: 14px;
    padding: 5px;
  }

  &__tbody {
    display: block;
    height: 168px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--primary-color);
      box-shadow: none;
      border-radius: 14px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 14px;
    }
  }

  &__tbody-tr {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;

    td:first-child {
      text-align: start;
    }

    td:last-child {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
      text-align: center;
      font-size: 12px;
      border-bottom: 2px solid var(--color-border-grey);
      padding: 7px;
      padding-bottom: 8.5px;
  
      &:hover {
        font-family: 'Avenir Next GEO W05 Demi';
        color: var(--primary-color);
        cursor: pointer;
      }
    }
  }

  &__tbody-td {
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    text-align: center;
    font-size: 12px;
    border-bottom: 2px solid var(--color-border-grey);
    border-right: 2px solid var(--color-border-grey);
    padding: 7px;

    &:hover {
      font-family: 'Avenir Next GEO W05 Demi';
      color: var(--primary-color);
      cursor: pointer;
    }
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  display: block;
}

