.demands-summary {
  display: block;
  width: 100%;
}

.demands-summary-container {
  background-color: none;
  border: 2px solid var(--color-border-grey);
  border-radius: 6px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.demands-summary-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    &__legend {
      display: flex;
      flex-direction: column;
    }

    &__icon-background {
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 4px;
      height: 40px;
      width: 40px;
    }

    &__icon {
      color: var(--primary-color);
      height: 25px;
      width: 25px;
    }
  }
}

.demands-summary-content-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-right: 2px solid var(--color-border-grey);
  &__legend {
    display: flex;
    flex-direction: column;
  }

  &__value {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 40px;
    text-align: center;
    color: var(--color-text);
  }

  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    color: var(--color-text);
  }

  &__icon-background {
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    height: 40px;
    width: 40px;
  }

  &__icon {
    color: var(--primary-color);
    height: 25px;
    width: 25px;
  }
}
