.c-text-editor {
  margin-bottom: 100px;

  &-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;
  }

  &-remove__button {
    background: none;
    border: none;
  }

  &__button {
    display: flex;
    justify-content: space-between;
  }
}

.c-editor {
  margin: 10px 0;

  &__textarea {
    border: 1px var(--color-medium-grey) solid;
    padding: 0px 5px;
    height: 100px;
    font-family: sans-serif;
  }
}