.financial-general-information {
  display: flex;
  flex-direction: column;
}

.financial-general-information-filter {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  position: relative;
  z-index: 1000;

  &__title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    span {
      font-family: 'Avenir Next Geo W05 Demi';
      color: var(--color-grey);
      font-size: 16px;
    }
  }

  &__icon {
    padding-top: 5px;
    cursor: pointer;
  }
}

.financial-general-information-filter-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 230px;
  width: 230px;

  &__radio-group {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .MuiSvgIcon-root {
    font-size: 18px;
  }
}

.financial-general-information-filter-date-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 0px 5px 5px 5px;

  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
    padding-left: 6px;
  }

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    margin-left: 5px;
    height: 25px;
    width: 100px;
    z-index: 3000;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }
  }
}

.financial-general-information-content {
  display: flex;
  flex-direction: row;
}

.financial-general-information-first-content {
  display: flex;
  flex-direction: column;

  &__cards-container {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-bottom: 50px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 15px;

    &--label {
      font-family: 'Avenir Next Geo W05 Bold';
      color: var(--color-genoa-dark-blue);
      font-size: 17px;
    }

    &--value {
      font-family: 'Avenir Next Geo W05 Regular';
      color: var(--color-grey);
      font-size: 17px;
      text-align: left;
      padding-top: 5px;
    }
  }

  &__card-top-10-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    box-sizing: border-box;
  }
}

.financial-general-information-veritical-bar {
  background-color: #e6edff;
  margin-left: 40px;
  margin-right: 40px;
  width: 1px;
}

.financial-general-information-second-content {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  box-sizing: border-box;

  &__cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 50px;

    &--label {
      font-family: 'Avenir Next Geo W05 Bold';
      color: var(--color-genoa-dark-blue);
      font-size: 17px;
    }

    &--value {
      font-family: 'Avenir Next Geo W05 Regular';
      color: var(--color-grey);
      font-size: 17px;
      text-align: left;
      padding-top: 2px;
    }
  }
}
