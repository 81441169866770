.modal-dark-bg-edit-message {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  -webkit-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  animation: fade-in 0.225s forwards;

  &::after {
    animation: fade-out 0.3s forwards;
  }

  .leave {
    animation: down-up 0.2s forwards;
  }
}

@keyframes fade-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fade-out {
  from {
    background-color: rgba(0, 0, 0, 0.7);
  }
  to {
    background-color: transparent;
  }
}

@keyframes down-up {
  from {
    transform: translateY(calc(50% - 200px));
  }
  to {
    transform: translateY(-100%);
  }
}

.modal-edit-message {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.modal-edit-message-content {
  background: var(--color-tab-light-grey);
  border-radius: 4px;
  height: 320px;
  width: 550px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 20px 20px;
  }

  &__title {
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
  }
  
  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px 10px 20px 20px;
  }

  &__textarea {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 2px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 95%;
    text-align: start;
    height: 100px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    &--warning {
      font-family: 'Avenir Next GEO W05 Demi';
      color: var(--color-red);
      font-size: 14px;
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
  }
}