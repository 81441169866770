.request-exclusion {
  box-sizing: content-box;
  min-height: 100.1vh;
  width: 700px;
}

.request-exclusion-header {
  background-color: var(--color-light-blue-alt);
  padding: 25px 20px 5px 25px;
  height: 50px;

  &__title {
    font-family: 'Avenir Next GEO W05 Heavy';
    font-size: 20px;
    color: #fff;
  }
}

.request-exclusion-container {
  padding: 25px 20px 5px 25px;
}

.request-exclusion-beneficiary-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;

  &__title {
    font-family: "Avenir Next GEO W05 Demi";
    font-size: 16px;
    color: var(--color-strong-blue);
  }
}

.request-exclusion-beneficiary-info-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.request-exclusion-beneficiary-info-content {
  display: flex;
  flex-direction: row;
  gap: 5px;

  &__icon {
    color: var(--color-dark-blue);
  }

  &__span {
    font-family: "Avenir Next GEO W05 Regular";
    font-size: 14px;
    color: var(--color-grey);
  }
}

.request-exclusion-plan-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;

  &__title {
    font-family: "Avenir Next GEO W05 Demi";
    font-size: 16px;
    color: var(--color-strong-blue);
  }

  &__label {
    font-family: "Avenir Next GEO W05 Regular";
    font-size: 14px;
    color: var(--color-grey);
    text-align: center;
  }
}

.request-exclusion-plan-info-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.request-exclusion-plan-select {
  display: flex;
  column-gap: 15px;
  align-items: center;
}

.request-exclusion-plan-option {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--color-light-blue-alt);
  border-radius: 6px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;

  span {
    font-family: "Avenir Next GEO W05 Demi";
    font-size: 14px;
    color: var(--color-grey);
  }


  height: 44px;
  width: 450px;

  &--disabled {
    background-color: var(--color-disabled-grey);
    border: 2px solid var(--color-disabled-grey);
    cursor: not-allowed;

    span {
      font-family: "Avenir Next GEO W05 Demi";
      font-size: 14px;
      color: #fff;
    }
  }

  &--active {
    background-color: var(--color-light-blue-alt);
    border: 2px solid var(--color-light-blue-alt);
    cursor: pointer;

    span {
      font-family: "Avenir Next GEO W05 Demi";
      font-size: 14px;
      color: #fff;
    }
  }
}

.request-exclusion-alert-icon-container {
  display: flex;
  align-items: center;
  height: 40px;

  &__icon {
    color: var(--color-orange);
    margin-top: 1px;
  }
}

.request-exclusion-schedule {
  position: absolute;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  z-index: 1000;
  background-color: white;
  padding: 0px 10px 0px 10px;
  width: 300px;
  transform: translateX(-22px) translateY(calc(50% + 22px));
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;

  &__top-stripe {
    background-color: var(--color-orange);
    width: calc(100% + 20px);
    transform: translateX(-10px) translateY(-1px);
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    height: 4px;
  }
}

.request-exclusion-schedule-display {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 5px;
  row-gap: 7px;

  &__disclaimer {
    font-size: 13px;
    line-height: 13px;
  }

  .loading-spinner {
    color: var(--color-grey);
  }
}

.request-exclusion-schedule-display::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 36px;
  margin-left: -8px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent var(--color-orange);
}

.request-exclusion-document-input-group {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;

  label {
    font-weight: bold;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  &__input-label {
    margin-top: 10px;
    max-width: max-content;

    input {
      display: none;
    }

    .attach-documents {
      display: flex;
      align-items: center;
      background: transparent;
      font-family: "Avenir Next GEO W05 Bold";
      font-size: 15px;
      color: var(--color-strong-blue);
      cursor: pointer;

      transition: all 0.4s;

      &:hover {
        text-decoration: underline;
            }
    }
  }

  &__file {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    margin-top: 5px;
    width: 100%;

    span {
      font-size: 14px;
      color: var(--color-grey);
    }

    .btn-clear {
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      cursor: pointer;

      svg {
        color: #fb2916;
      }
    }
  }
}

.request-exclusion-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.request-exclusion-message-confirmation {
  margin-top: 20px;
  margin-bottom: 50px;

  &__sucess-message {
    color: var(--color-green);
    font-size: 15px;
    font-family: "Avenir Next GEO W05 Demi";
  }

  &__error-message {
    color: var(--color-alert-red);
    font-size: 15px;
    font-family: "Avenir Next GEO W05 Demi";
  }
}
