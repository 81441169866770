body,
input,
button,
select,
option {
  font-family: 'Avenir Next GEO W05 Regular', sans-serif;
}

h1 {
  font-family: 'Avenir Next GEO W05 Heavy', sans-serif;
}

h2,
h3,
h4,
h5,
h6,
.font-demi {
  font-family: 'Avenir Next GEO W05 Demi', sans-serif;
}

input {
  font-family: 'Avenir Next GEO W05 Regular';
}

.anime-left {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;

  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
}
.MuiRadio-colorSecondary.Mui-checked {
  color: var(--primary-color) !important;
}
