.personal-data-forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  box-sizing: border-box;
  padding: 30px;
  background: var(--color-background-grey);

  .personal-data-form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    background: #ffff;
    border: 1px solid #ffff;
    box-shadow: 2px 1.5px var(--color-disabled-grey);
    border-radius: 6px;
    padding: 5px 10px;

    &--plus {
      width: 400px;
      height: 220px;
    }

    &--principal {
      width: 400px;
      height: 210px;
    }

    &__inputs-principal {
      display: flex;
      flex-direction: row;
      column-gap: 15px;
      padding-top: 30px;
    }

    &__inputs {
      display: flex;
      flex-direction: row;
      column-gap: 15px;
    }

    &__input-label {
      font-size: 14px;
      padding-left: 5px;
    }

    &__input {
      height: 30px;
    }

    &__span-error {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 14px;
      padding-left: 6px;
      color: red;
    }

    &__button-remove {
      display: flex;
      justify-content: flex-end;
    }
  }
}