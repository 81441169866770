.policy-owners-details {
  display: flex;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;

    &--main-panel {
      margin: 0;
      // min-height: 100vh;
    }
  }

  &__consult {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    justify-content: space-between;
    width: 100%;

    &--presentation-container {
      display: flex;
      flex-direction: row;
      column-gap: 100px;
      padding: 30px 20px;
    }

    &--button-container {
      display: flex;
      flex-direction: row;
      column-gap: 40px;
    }

    &--title {
      color: var(--color-grey);

      h1 {
        font-size: 1.2rem;
      }
    }

    &--filter {
      display: flex;
      flex-direction: column;
      padding: 30px 20px;

      span {
        font-size: 16px;
        font-family: 'Avenir Next GEO W05 Demi';
      }
    }

    &--radius-group {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      .MuiFormGroup-root {
        flex-direction: row;
      }

      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }

    &--radio {
      padding: 5px;
      text-align: center;
    }
  }
}
