.manager-request-table-result {
  display: grid;
  grid-template-columns: 0.1fr 2fr 1fr 3fr 1fr 0.3fr;
  column-gap: 10px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  background: #ffff;
  height: 40px;
  width: 100%;
  box-sizing: border-box;

  &__span {
    text-align: left;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__icons-container {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
  }

  &__img {
    height: 20px;
    width: 20px;
  }

  &__icon {
    color: var(--color-hover-green);
    padding-left: 20px;
    height: 20px;
    width: 20px;
  }

  &__funel-steps {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  
  &__arrows {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  &__arrow {
    width: 60px;
    height: 30px;
    background-color: #ddd;
    clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 20% 50%);
    margin-left: -12px; // Ajuste para sobrepor as setas e criar o efeito de encaixe

    &--first {
      clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 0 50%);
      margin-left: 0; // A primeira seta não precisa do encaixe
    }

    &--active {
      background-color: #0554F2; // Cor para a seta ativa
    }
  }

  &__label-container {
    display: flex;
    align-items: center;
    position: absolute;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    z-index: 10;
    background-color: rgba(216, 216, 216, 0.979);
    padding: 10px;
    transform: translateX(calc(-19%)) translateY(calc(-60%));
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 10px;
    min-width: 150px;
  }

  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    text-align: center;
    color: var(--color-genoa-dark-blue);
  }
}
