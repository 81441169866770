.register-policy-owner {
  display: flex;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.register-policy-owner-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.register-policy-owner-content {
  margin: 25px 0;
}

.register-policy-owner-content-title {
  margin: 10px 0px 30px 0px;
  h1 {
    font-family: 'Avenir Next GEO W05 Bold';
    color: var(--color-grey);
    font-size: 20px;
  }
}

.register-policy-owner-form {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
}

.register-policy-owner-form-grids {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 80px;
}

.register-policy-owner-form-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 20px;
}

.register-policy-owner-form-input-group {
  display: flex;
  flex-direction: column;

  label {
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
    font-size: 14px;
  }

  input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    margin-top: 7px;
    width: 270px;
    text-align: start;
    height: 32px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }

  &__area-sub {
    margin-bottom: 10px;
  }

  &__label-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    label {
      font-family: 'Avenir Next GEO W05 Demi';
      color: var(--color-grey);
      font-size: 14px;
    }
  }

  &__button {
    padding-left: 132px;
  }

  &__legend {
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
  }

  &__button-remove {
    display: flex;
    justify-content: center;
  }

  &__button-register {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding-top: 20px;
  }

  &__link {
    font-family: 'Avenir Next GEO W05 Demi';
    color: #FFF;
    font-size: 14px;
    text-decoration: none;
    outline: none;

    &:hover {
      text-decoration: none;
      outline: none;
    }
  }
}

.dados-receita {
  margin: 10px 0;
}

.register-policy-owner-form-checkbox-area {
  label {
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
    font-size: 14px;
  }
}

.register-policy-owner-form-checkbox-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;

  label.select-label{
    font-family: 'Avenir Next GEO W05 Regular';
    display: flex;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 5px;
    color: var(--color-grey);
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    height: 48px;
    width: 100%;
  }

  label.select-label.active{
    background: var(--primary-color);
    color: #FFF;
  }
}