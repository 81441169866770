.third-step {
  width: 460px;
  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  &__container-title {
    margin-bottom: 20px;
  }

  &__title {
    color: var(--color-grey);
    font-family: 'Avenir Next GEO W05 Bold';
    font-size: 18px;
  }

  &__label {
    padding-left: 15px;
  }

  .third-step-binding-date {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    &__span {
      color: var(--color-grey);
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      padding-left: 15px;
    }
  
    &__custom-date-picker-width,
    &__custom-date-picker-width > div.react-datepicker-wrapper,
    &__custom-date-picker-width > div > div.react-datepicker__input-container,
    &__custom-date-picker-width > div > div.react-datepicker__input-container input {
      width: 100%;
    }
  
    &__date-picker {
      color: var(--color-dark-gray);
      background: #fff;
      border: 2px solid var(--color-primary-blue);
      border-radius: 48px;
      text-align: center;
      height: 48px;
    }
  }

  .third-step-register-number {
    &__label {
      padding-left: 15px;
    }

    &__input {
      height: 40px;
      width: 100%;
    }
  }

  .third-step-container-input {
    &__span {
      position: relative;
      display: inline-block;
    }
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-top: 20px;
  }

  .third-step-save {
    padding: 10px;

    div:nth-last-child(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding-left: 15px;
    }

    &__span {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      color: var(--color-grey);
      margin-bottom: 10px;
      display: block;
    }

    &__span-success {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      color: var(--color-green);
    }
  }
}

.informations-modal {
  position: absolute;
  top: -40px; // ajuste conforme necessário
  left: 350px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  width: 300px; // ajuste conforme necessário

  span {
    color: #333;
    font-size: 14px;
  }
}
