.health-update {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  padding-right: 15px;
  padding-left: 15px;

  .health-update-back {
    margin-top: 10px;
    margin-bottom: 20px;

    &__button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      color: var(--color-dark-blue);
      border-radius: 13px;
    }

    &__span {
      font-size: 14px;
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Bold';
      color: var(--color-dark-blue);
    }
  }

  .health-update-title {
    margin-bottom: 30px;
    &__span {
      color: var(--primary-color);
      font-size: 17px;
      font-family: "Avenir Next GEO W05 Bold";
    }
  }

  .health-update-form {
    display: flex;
    flex-direction: column;

    .health-update-form-content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3rem;
    }

    .health-update-form-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 10px;
    }

    .health-update-form-section-select-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 6px;

      &:first-child {
        margin-top: 0px;
      }

      &--select {
        padding-top: 30px;
      }

      &__label {
        font-family: 'Avenir Next GEO W05 Demi';
        font-size: 17px;
        color: var(--color-grey);
        margin-bottom: 10px;
        display: block;
      }

      &__select {
        font-family: 'Avenir Next GEO W05 Regular';
        width: 100%;
        height: 48px;
        appearance: none;
        text-align: center;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position-x: 96%;
        background-position-y: 17px;
        font-size: 16px;
        padding: 0px 10px;
        color: var(--color-grey);
        border: 2px solid var(--primary-color);
        border-radius: 24px;
  
        :focus-visible{
          outline: none;
        }
      }
    }
  }

  .health-update-form-section-input-group {
    &__label {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 17px;
      color: var(--color-grey);
      margin-bottom: 10px;
      display: block;
    }

    &__input {
      background-color: #fff;
      border: 2px solid var(--primary-color);
      border-radius: 24px;
      margin-bottom: 6px;
      padding: 12px;
      opacity: 1;
      font-size: 16px;
      color: var(--color-grey);
      font-family: 'Avenir Next GEO W05 Regular';
      width: 100%;
      text-align: center;
      height: 48px;
      &::placeholder {
        color: #c1c1c1;
      }

      &:focus-visible {
        outline-color: var(--primary-color);
      }
    }
  }

  .health-update-form-section-date-picker-group {
    &__label {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 17px;
      color: var(--color-grey);
      margin-bottom: 10px;
      display: block;
    }

    &__date-picker {
      color: var(--color-grey);
      border-radius: 48px;
      width: 100%;
      height: 48px;
      background: #FFFFFF;
      border: 2px solid var(--primary-color);
      text-align: center;
      margin: 0px 0 6px 0;
      font-size: 16px;

      &::placeholder {
        color: var(--color-grey);
      }
    }
  }

  .health-update-form-section-textarea-group {
    &__label {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 17px;
      color: var(--color-grey);
      margin-bottom: 10px;
      display: block;
    }

    &__textarea {
      background-color: #fff;
      border: 2px solid var(--primary-color);
      border-radius: 24px;
      padding: 12px;
      box-sizing: border-box;
      opacity: 1;
      font-size: 16px;
      color: var(--color-grey);
      font-family: 'Avenir Next GEO W05 Regular';
      width: 100%;

      &::placeholder {
        text-align: center;
        color: #c1c1c1;
      }

      &:focus-visible {
        outline-color: var(--primary-color);
      }

      &--active {
        background-color: #fff;
        border: 2px solid var(--primary-color);
        border-radius: 24px;
        width: 100%;
        opacity: 1;
        font-size: 16px;
        padding: 12px;
        box-sizing: border-box;
        color: var(--color-grey);
        text-align: center;
        font-family: 'Avenir Next GEO W05 Regular';
        margin-bottom: 10px;

        &::placeholder {
          color: #c1c1c1;
        }

        &:focus-visible {
          outline-color: var(--primary-color);
        }
      }

      &--disabled {
        background-color: #BBBBBB;
        border: 2px solid #c3c3c3;
        color: #BBBBBB;
        border-radius: 24px;
        opacity: 0.25;
        padding: 12px;
        box-sizing: border-box;
        text-align: center;
        width: 100%;
        height: 173.6px;
        margin-bottom: 10px;
      }
    }
  }

  .health-update-form-section-checkbox-group {
    &__label {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 17px;
      color: var(--color-grey);
      margin-bottom: 10px;
      display: block;
    }

    &__options {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 0px 20px 0px;
    }

    &__select-label {
      display: flex;
      font-size: 16px;
      background-color: #fff;
      border: 2px solid var(--primary-color);
      border-radius: 48px;
      width: 100%;
      height: 48px;
      color: var(--color-grey);
      justify-content: center;
      text-align: center;
      box-sizing: border-box;
      align-items: center;
      padding: 10px;
      cursor: pointer;

      &--active {
        background: var(--primary-color);
        color: #FFF;
      }
    }
  }

  .health-update-form-plans {
    margin-top: 30px;

    .health-update-form-plans-title {
      margin-bottom: 20px;

      &__span {
        color: var(--primary-color);
        font-size: 17px;
        font-family: "Avenir Next GEO W05 Bold";
      }
    }
  }

  .health-update-form-section-remove {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    width: 30%;

    &__button {
      background: none;
      margin-bottom: 5px;
      border: none;
      color: #ff0757;
      cursor: pointer;
      font-size: 17px;
      font-family: 'Avenir Next GEO W05 Demi';

      &--activated {
        color: var(--color-primary-blue-hover);
      }

      &--deactivated {
        color: var(--color-light-blue-alt);
      }
    }

    &__input {
      background-color: #fff;
      border: 2px solid var(--primary-color);
      border-radius: 24px;
      margin-bottom: 6px;
      padding: 12px;
      opacity: 1;
      font-size: 16px;
      color: var(--color-grey);
      font-family: 'Avenir Next GEO W05 Regular';
      text-align: center;
      height: 48px;
      width: 100%;
      &::placeholder {
        color: #c1c1c1;
      }

      &:focus-visible {
        outline-color: var(--primary-color);
      }

      &--transition {
        transition: all 200ms ease-in-out;
      }

      &--deactivated {
        border: 2px solid var(--color-disabled-grey);
        color: var(--color-light-blue-alt);
        background-color: rgba(255, 255, 255, 0.7);
      }
    }

    &__button-icon {
      background: none;
      border: none;
      cursor: pointer;
    }

    &__icon-trash {
      color: #ff0757;
    }

    &__icon {
      display: flex;
      column-gap: 30px;
    }

    &__bulb-icon {
      font-size: 18px;
      padding-left: 5px;
      transition: color 200ms ease-in-out;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .health-update-form-section-remove-input {
    background-color: #fff;
    border: 2px solid var(--primary-color);
    border-radius: 24px;
    margin-bottom: 6px;
    padding: 12px;
    opacity: 1;
    font-size: 16px;
    color: var(--color-grey);
    font-family: 'Avenir Next GEO W05 Regular';
    text-align: center;
    height: 48px;
    width: 30%;
    &::placeholder {
      color: #c1c1c1;
    }

    &:focus-visible {
      outline-color: var(--primary-color);
    }
  }

  .health-update-form-section-button-group {
    padding-top: 10px;
    padding-bottom: 10px;
    &__button {
      cursor: pointer;
      border: none;
      color: var(--color-dark-blue);
      background: none;
      padding: 10px 10px;
      margin: 10px 0 0 0;
      border-radius: 10px;
      font-size: 14px;
      font-family: 'Avenir Next GEO W05 bold';
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
