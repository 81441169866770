.create-dental-insurance {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
    padding-left: 15px;
    padding-right: 15px;

    &__back {
      margin-top: 10px;
      margin-bottom: 20px;
      &-btn {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        color: var(--color-dark-blue);
        border-radius: 13px;
      }

      span {
        font-size: 14px;
        cursor: pointer;
        font-family: 'Avenir Next GEO W05 Bold';
        color: var(--color-dark-blue);
      }

      svg {
        font-size: 1.5rem;
        cursor: pointer;
      }
    }

    &__title {
      color: var(--color-primary-blue);
      font-size: 17px;
      margin-bottom: 30px;
    }

    &__form {
      display: flex;
      flex-direction: column;
    }

    &__grids {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3rem;
    }

    &__col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 10px;
    }

    &__label {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 17px;
      color: var(--color-grey);
      margin-bottom: 10px;
      display: block;
    }

    &__apolice-details {
      background-color: #fff;
      border: 2px solid var(--color-primary-blue);
      border-radius: 24px;
      margin-bottom: 6px;
      padding: 12px;
      opacity: 1;
      font-size: 16px;
      color: var(--color-grey);
      font-family: 'Avenir Next GEO W05 Regular';
      width: 100%;
      text-align: center;
      height: 48px;
      ::placeholder {
        color: #c1c1c1;
      }

      :focus-visible {
        outline-color: var(--color-primary-blue);
      }
    }

    &__date-picker {
      font-family: 'Avenir Next GEO W05 Regular';
      background-color: #fff;
      border: 2px solid var(--color-primary-blue);
      color: var(--color-grey);
      border-radius: 24px;
      padding: 12px;
      font-size: 16px;
      width: 100%;
      text-align: center;
      height: 48px;
    }
  }

  .input-file {
    margin-top: 20px;

    &__title {
      font-family: 'Avenir Next GEO W05 Bold';
      color: var(--color-primary-blue);
      font-size: 17px;
      margin-bottom: 30px;
    }

    &__files {
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding: 10px 10px;
      margin: 20px 0 30px 0;
      column-gap: 10px;
      height: 44px;
    }

    &__label-file-documents {
      cursor: pointer;
      font-size: 17px;
      color: var(--color-dark-blue);
      background: none;
      font-family: 'Avenir Next GEO W05 Bold';
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }

      input {
        display: none;
      }

      .input-file__name {
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
    }
  }
}

.ds-select {
  display: flex;
  flex-direction: column;

  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    color: var(--color-grey);
    margin-bottom: 10px;
    display: block;
  }

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    width: 100%;
    height: 48px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 17px;
    font-size: 16px;
    color: var(--color-grey);
    border-radius: 24px;

    &:focus-visible {
      outline: none;
    }

    &--broker {
      border: 2px solid var(--color-primary-blue);
    }
  }
}

.ds-input {
  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    color: var(--color-grey);
    margin-bottom: 10px;
    display: block;
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 2px solid var(--color-primary-blue);
    color: var(--color-grey);
    border-radius: 24px;
    padding: 12px;
    font-size: 16px;
    width: 100%;
    text-align: start;
    height: 48px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
    }

    &:focus-visible {
      outline-color: var(--color-primary-blue);
    }
  }
}

.ds-textarea {
  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    color: var(--color-grey);
    margin-bottom: 10px;
    display: block;
  }

  &__textarea {
    background-color: #fff;
    border: 2px solid var(--color-primary-blue);
    border-radius: 24px;
    padding: 12px;
    box-sizing: border-box;
    opacity: 1;
    font-size: 16px;
    color: var(--color-grey);
    font-family: 'Avenir Next GEO W05 Regular';
    width: 100%;

    &::placeholder {
      text-align: start;
      color: var(--color-disabled-grey);
    }

    &:focus-visible {
      outline-color: var(--color-primary-blue);
    }
  }
}
