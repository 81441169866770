.request-inclusion {
  box-sizing: content-box;
  min-height: 116vh;
  width: 700px;
  background: var(--color-background-grey);

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    background-color: var(--color-light-blue-alt);
    padding-top: 20px;
    padding-left: 40px;
    height: 100px;
  }

  &__title {
    color: #ffff;
    font-family: 'Avenir Next GEO W05 Heavy';
    font-size: 18px;
  }

  &__span {
    color: #ffff;
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;

    &--success {
      color: green;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
    }

    &--error {
      color: red;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
    }
  }

  &__button-container {
    &--without {
      padding-top: 250px;
      padding-left: 40px;
    }

    &--withForms {
      padding: 50px;
      padding-left: 40px;
    }
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 240px;
    padding: 20px;
    padding-left: 40px;
  }
}
