.broker-beneficiaries-table {
  display: flex;
  flex-direction: column;
  // min-height: 60vh;
  box-sizing: border-box;

  &__checkbox-on-table {
    margin-left: 3px;

    .button-edit-on-table {
      color: var(--color-dark-blue);
      margin: 5px;
      margin-bottom: 0;
      margin-bottom: -1px;
      margin-left: 0;
      border-radius: 4px;

      &:hover {
        color: #fff;
        background-color: var(--color-dark-blue);
        padding: 0;
        margin: 5px;
        margin-bottom: 0;
        margin-bottom: -1px;
        margin-left: 0;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .checkbox {
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  &__edit-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--color-dark-blue);
    cursor: pointer;

    &:last-child {
      margin-right: 20px;
    }
  }
}

.broker-beneficiaries-theader {
  display: grid;
  grid-template-columns: 0.2fr 1.5fr 0.5fr 0.5fr 2.5fr 0.5fr 0.5fr 0.5fr 0.5fr 80px;
  align-items: center;
  border: 2px solid var(--color-tab-light-grey);
  box-sizing: border-box;
  padding: 5px;
  height: 40px;
  width: 100%;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  &__th {
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
    font-size: 16px;
  }
}

.broker-beneficiaries-tbody {
  display: grid;
  grid-template-columns: 0.2fr 1.5fr 0.5fr 0.5fr 2.5fr 0.5fr 0.5fr 0.5fr 60px 60px 60px;
  background: #fff;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;

  &:hover {
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;
    background-color: var(--color-tab-light-grey);
  }

  &__td {
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
  }

  &__td-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.broker-beneficiaries-tbody--expanded {
  display: grid;
  grid-template-columns: 0.2fr 1.5fr 0.5fr 0.5fr 2.5fr 0.5fr 0.5fr 0.5fr 60px 60px 60px;
  background: #fff;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  border-bottom: none;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;

  &__td {
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
  }

  &__td-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.broker-beneficiaries-tbody-expanded {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 25px 5px 25px;
  border-top: 2px dashed var(--color-tab-light-grey);
  border-right: 2px solid var(--color-tab-light-grey);
  border-left: 2px solid var(--color-tab-light-grey);
  border-bottom: 2px solid var(--color-tab-light-grey);
  margin-bottom: 5px;

  &--animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
}

.broker-beneficiaries-tbody-expanded-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 10px;
}

.broker-beneficiaries-tbody-expanded-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;

  span {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;
    color: var(--color-grey);
  }

  &:first-child>.expanded-content-icon {
    color: var(--color-dark-blue);
  }

  &:nth-child(2)>.expanded-content-icon {
    color: var(--color-dark-blue);
  }

  &:last-child>.expanded-content-icon {
    color: var(--color-dark-blue);
  }
}

.expanded-content-icon {
  &--clock {
    color: var(--primary-color);
  }
}

.broker-beneficiaries-tbody-expanded-edit {
  padding: 10px 0px 0px 0px;
}

.paper-popup-delete-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 80px;
  width: 500px;
}

.popup-options-container {
  margin-top: 5px;
  font-family: 'Avenir Next GEO W05 Regular';
}

.popup-message {
  font-weight: bold;
}

.popup-options {
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

.calendar-icon-broker {
  width: 10px;
  height: 10px;
  color: var(--primary-color);

  transform: translateX(-8px) translateY(8px);
}

.movimentacao-content-broker {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding-top: 8px;
  padding-bottom: 4px;
  font-size: 14px;
  color: var(--color-grey);
  row-gap: 8px;

  .movimentacao-content-title-broker {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      font-family: "Avenir Next Geo W05 Demi";
    }
  }

  .movimentacao-info-broker {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;

    .calendar {
      width: 13px;
      height: 13px;
      color: var(--color-dark-blue);

      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
}

.check-icon {
  margin-right: 2px;
  color: green;
}

.cross-icon {
  margin-right: 2px;
  color: red;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  gap: 15px;

  span {
    font-size: 1rem;
  }
}

.loading-spinner {
  margin-bottom: 15px;
  color: var(--primary-color);
}

.active-icon {
  margin-left: 5px;
  margin-right: 5px;
  color: var(--color-dark-blue);

  svg {
    width: 18px;
    height: 18px;
    fill: currentColor;
  }
}

.in-removal-icon {
  margin-left: 5px;
  color: #f8c000;

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}

.inactive-icon {
  margin-left: 5px;
  color: var(--color-disabled-grey);

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}