.manager-request-table-header {
  display: grid;
  grid-template-columns: 0.1fr 2fr 1fr 3fr 1fr 0.3fr;
  column-gap: 10px;
  border: 2px solid var(--color-tab-light-grey);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  
  &__column-name {
    align-content: center;

    &--modal {
      position: absolute;
      z-index: 1;
      transform: translateX(500px);
    }
  }

  &__span {
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__button-edit {
    color: var(--color-dark-blue);
    margin: 12px;
    margin-bottom: -2px;
    margin-left: 0;
    border-radius: 4px;
  }
}

.manager-request-table-header-edit-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  &__button-edit {
    color: var(--color-dark-blue);
    padding: 0;
    cursor: pointer;
  }

  &__delete-button {
    color: var(--color-dark-blue);
    padding: 0;
    cursor: pointer;
  }
}
