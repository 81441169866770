.first-step {
  width: 450px;
  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__button-next {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
