.fifth-step {
  width: 450px;
  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  &__container-title {
    margin-bottom: 20px;
  }

  &__title {
    color: var(--color-grey);
    font-family: 'Avenir Next GEO W05 Bold';
    font-size: 18px;
  }

  &__label {
    padding-left: 15px;
  }

  .fifth-step-container-select {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    &__span {
      position: relative;
      display: inline-block;
    }
  }

  .fifth-step-container-file-list {
    padding-top: 15px;
    &__span {
      font-family: 'Avenir Next GEO W05 Demi';
    }

    &__input-list {
      padding-top: 10px;
    }

    &__doc-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    &__message-error {
      color: red;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 12px;
      padding: 0px;
      padding-left: 15px;
    }
  }

  .fifth-step-container-hr-file {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__message-error {
      color: red;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 12px;
      padding: 0px;
      padding-left: 15px;
    }

    > div:last-child {
      flex-direction: column;
      width: 100%; // garante que o último filho ocupe toda a largura disponível
    }
  }

  .fifth-step-container-hr-doc-proposal {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    position: relative;
    cursor: pointer;
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-top: 20px;
  }

  .fifth-step-save {
    padding: 10px;

    div:nth-last-child(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding-left: 15px;
    }

    &__span {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      color: var(--color-grey);
      margin-bottom: 10px;
      display: block;
    }

    &__span-success {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      color: var(--color-green);
    }
  }
}

.informations-modal {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  width: 350px; // ajuste conforme necessário

  span {
    color: #333;
    font-size: 14px;
  }

  &--proposal-doc {
    top: -30px; // ajuste conforme necessário
    left: 290px;
  }

  &--inclusion-doc {
    top: -20px; // ajuste conforme necessário
    left: 200px;
  }
}
