.completed-inclusion {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  justify-content: center;
  padding-top: 30px;
  width: 100%;

  span {
    color: #6bbf59;
    font-family: 'Avenir Next GEO W05 Bold';
    font-size: 20px;
  }

  &__icon {
    color: #6bbf59;
    font-size: 40px;
    padding-left: 140px;
  }
}
