.picker {
  transform: scale(0.8);

  .rdrCalendarWrapper {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .picker__day-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .picker__activity-dot {
    position: absolute;
    bottom: 19px;
    right: 11px;
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 50%;
  }
}
