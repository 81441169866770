.registration-information {
  &__drawer {
  }
}

.paper-popup-delete-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: auto;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  z-index: 1000;
  border-radius: 8px;
  padding: 15px;
  user-select: none;

  .popup-message {
    font-weight: bold;
  }

  .popup-company-name {
    max-width: 500px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    text-decoration: underline;
  }

  .popup-beneficiaries {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    color: #555;
  }

  .popup-options-container {
    display: flex;
    gap: 10px;
    margin-top: 5px;

    .popup-options {
      display: flex;
      margin: 0 10px;
      align-items: center;
    
      &:hover {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}






