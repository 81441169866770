.checkbox-group {
  display: flex;
  justify-content: center;
  align-items: center;

  &__label {
    display: flex;
    box-sizing: border-box;
    font-family: 'Avenir Next GEO W05 Demi';
    padding: 20px 20px;
    border: 2px solid var(--color-primary-blue);
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    height: 50px;
    width: 100%;

    &--primary {
      background: transparent;
      color: rgb(30, 37, 82);
    }

    &--selected {
      background: var(--color-primary-blue);
      color: #fff;
    }
  }
}