.table-contracts {
  &__header-list {
    display: grid;
    grid-template-columns: 20px 200px 200px 300px 200px 200px 1fr 20px;
    align-items: center;
    border: 2px solid var(--color-tab-light-grey);
    box-sizing: border-box;
    margin-top: 20px;
    height: 40px;
    width: 100%;
  }
}

.table-contracts {
  &__body-list {
    display: grid;
    grid-template-columns: 20px 200px 200px 300px 200px 200px 1fr 20px;
    align-items: center;
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;
    box-sizing: border-box;
    background-color: var(--color-white);
    height: 40px;
    width: 100%;

    &:hover {
      border: 2px solid var(--color-tab-light-grey);
      border-top: none;
      background-color: var(--color-tab-light-grey);
    }
  }

  &__body-item {
    &--icon-product {
      padding-left: 20px;
    }

    &--button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 20px;
    }
  }
}
