.hr-tickets {
  display: flex;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.hr-tickets-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  width: calc(100% - 80px);

  &__main-painel {
    margin: auto;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;

    h1 {
      font-family: 'Avenir Next GEO W05 Heavy';
      font-size: 20px;
      color: var(--color-grey);
    }
  }
}

#sentinela {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background: #fff;
}

#sentinela-loading {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background: transparent;
}

.hr-tickets-initial-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 80px;
  padding-left: 20px;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.hr-tickets-initial-filter-search {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 51px;

  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    text-align: start;
    height: 25px;
    width: 100%;
    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }
}

.hr-tickets-initial-filter-content {
  display: flex;
  flex-direction: column;
  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__radio-group, .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    &--radio {
      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
  }

  &__datepicker-box-space {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 51px;

    &--group-container {
      display: grid;
      grid-template-columns: 125px 125px;
      margin-top: 3px;
      overflow: hidden;
      transition: all .6s cubic-bezier(0.4, 0, 0.2, 1);
    }
  
    &--group-container-hidden {
      display: grid;
      grid-template-columns: 125px 125px;
      margin-top: 3px;
      overflow: hidden;
      transition: all .6s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    color: var(--color-grey);
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    margin-left: 5px;
    height: 25px;
    width: 100px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }

    &::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
      color: var(--color-grey);
    }
  }

  &__datepicker-selector-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
}

.hr-tickets-filter {
  margin-top: 25px;
}

.hr-tickets-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
  align-items: center;
  box-sizing: border-box;

  &__order-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 3px;
    cursor: pointer;
  }
}

.hr-tickets-filter-container-select {
  display: flex;
  flex-direction: row;
  column-gap: 70px;

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    text-align: start;
    margin-top: 5px;
    height: 30px;
    width: 100%;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    &--menu-item {
      font-size: 14px;
    }
  }
}

.hr-ticket-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  background: #fff;
  border: 2px solid var(--color-tab-light-grey);
  padding: 35px 40px;
  box-sizing: border-box;
  height: 220px;
  width: 100%;
}

.hr-ticket-card-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.hr-ticket-card-container-content {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  &__icon {
    color: var(--color-dark-blue);
  }

  &__text {
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    font-size: 16px;
  }
}

.hr-ticket-card-type {
  span {
    display: block;
    padding: 10px;

    p {
      margin: 0;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      color: var(--color-light-blue-alt);
    }
  }
}

.hr-circular-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25vh;

  &__circular-spinner{
    color: var(--color-light-blue-alt);
    margin-bottom: 20px;
  }

  h3 {
    color: var(--color-grey);
    margin-top: 10px;
  }
}
