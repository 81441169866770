:root {
  --primary-color: rgb(69, 106, 237);
  --primary-color-rgba: rgba(69, 106, 237, 0.3);
  --secondary-color: #2e8548;
  --secondary-color-hover: #26703c;
  --color-text: rgb(59, 56, 56);
  --color-purple: rgba(110, 91, 205, 1);
  --color-cyan: rgba(108, 211, 255, 1);
  --color-dark-gray: #3b3838;
  --color-white: #fff;
  --color-light-blue: #6da2eb;
  --color-primary-blue: #456aed;
  --color-primary-light-blue: #00a3ff;
  --color-primary-blue-hover: #2b55eb;
  --color-dark-blue: #1e2552;
  --color-deep-blue: #181c3e;
  --color-genoa-dark-blue: #02024e;
  --color-background-grey: #f3f3f3;
  --color-background-light-blue: #00b8ff;
  --color-border-grey: #e6edff;
  --color-light-grey: #f8f8f8;
  --color-medium-grey: #f5f6f6;
  --color-grey: #3b3838;
  --color-pink: #f80055;
  --color-red: #de062d;
  --color-orange: #ff9a00;
  --color-subtle-grey: #878787;
  --color-light-grey-alt: #a1a1a1;
  --color-tab-light-grey: #f1f1f1;
  --color-tab-dark-grey: #e7e7e7;
  --color-subtitle-grey: rgba(0, 0, 0, 0.25);
  --color-disabled-grey: #c1c1c1;
  --color-light-blue-alt: #93a9f5;
  --color-strong-blue: #5c81ff;
  --color-green: #5bb347;
  --color-luminous-green: #02cd02;
  --color-hover-green: #1aa31a;
  --color-alert-red: #f81111;
  --color-transparent: rgba(0, 0, 0, 0);
  --color-page-grey: #d0d0d0;
}
