.modal-dark-bg-schedule-task {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  -webkit-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  animation: fade-in 0.225s forwards;

  &::after {
    animation: fade-out 0.3s forwards;
  }

  .leave {
    animation: down-up 0.2s forwards;
  }
}

@keyframes fade-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fade-out {
  from {
    background-color: rgba(0, 0, 0, 0.7);
  }
  to {
    background-color: transparent;
  }
}

@keyframes down-up {
  from {
    transform: translateY(calc(50% - 200px));
  }
  to {
    transform: translateY(-100%);
  }
}

.modal-schedule-task {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.schedule-task-content {
  background: var(--color-tab-light-grey);
  border-radius: 4px;
  padding-left: 22px;
  height: 520px;
  width: 545px;

  &__title {
    display: flex;
    justify-content: flex-start;
    padding: 20px 15px 5px 10px;
  }
}

.schedule-task-form {
  display: flex;
  flex-direction: column;
}

.schedule-task-form-first-content {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px 0px;
}

.schedule-task-form-second-content {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 10px 22px 20px 0px;

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 2px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 30%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 14px;
    }
  }
}

.schedule-task-form-content {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;

  &--textarea {
    justify-content: flex-start;
  }

  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    color: var(--color-subtle-grey);
    margin-right: 5px;
  }

  &__span {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;
    color: var(--color-grey);
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 2px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 94%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 14px;
    }
  }

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 2px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 90%;
    text-align: start;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      margin-top: 2px;
      border-top: none;
      border-radius: 2.67px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding-bottom: 2.67px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }
  }

  &__textarea {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 2px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 7px;
    font-size: 14px;
    width: 198%;
    text-align: start;
    height: 60px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }
}

.schedule-task-button {
  padding: 25px 22px 10px 0px;
}
