.field {
  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    color: var(--color-grey);
    margin-bottom: 10px;
    display: block;
  }

  &__group {
    &--disabled {
      .field__input {
        background: #e3e3e3;
        border-color: #c5c5c5;
        pointer-events: none;
      }
    }
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 2px solid var(--color-primary-blue);
    color: var(--color-grey);
    border-radius: 24px;
    padding: 12px;
    font-size: 16px;
    width: 100%;
    text-align: start;
    height: 48px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
    }

    &:focus-visible {
      outline-color: var(--color-primary-blue);
    }

    &--invalid {
      border-color: red;
    }

    &--error-message {
      color: red;
      font-size: 14px;
      margin-top: 5px;
    }
  }
}
