.top-10-lifetime-table {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &__header {
    align-items: center;
    padding-left: 10px;
    padding-bottom: 5px;
  }

  &__title {
    font-family: 'Avenir Next Geo W05 Bold';
    color: var(--color-genoa-dark-blue);
    font-size: 18px;
    text-align: left;
  }

  &__subtitle {
    font-family: 'Avenir Next Geo W05 Demi';
    color: var(--color-genoa-dark-blue);
    font-size: 14px;
    text-align: left;
    padding-top: 3px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 5px;
    padding: 5px 10px 10px 10px;
  }

  &__content-item {
    display: grid;
    grid-template-columns: 250px 1fr;
    column-gap: 4px;
  }

  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
    text-align: left;

    &.label-truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 5px;
      display: block;
    }
  }

  &__title-pop-up {
    display: flex;
    position: absolute;
    align-items: center;
    background-color: #c1c1c1;
    padding: 5px;
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);

    height: 30px;
    max-width: 500px;
  }

  &__value {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
    text-align: left;
  }
}
