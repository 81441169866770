.form-create-business {
  &__drawer-content {
    border-radius: 10px;
    background-color: #fff;
    width: 450px;
    position: fixed;
    left: calc(50% - 300px);
    animation: up-down 0.2s forwards;

    &--leave {
      animation: down-up 0.2s forwards;
    }

    @keyframes up-down {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(calc(50% - 200px));
      }
    }

    @keyframes down-up {
      from {
        transform: translateY(calc(50% - 200px));
      }
      to {
        transform: translateY(-100%);
      }
    }
  }

  &__title {
    font-family: 'Avenir Next Geo W05 Demi';
    font-size: 18px;
    padding: 15px 20px;
    font-weight: 700;
    background: #f5f5f6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    svg {
      cursor: pointer;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    gap: 25px;
    padding: 0 20px;
  }

  &__checkbox-container {
    height: 58px;
    display: flex;
    align-items: center;
  }

  &__container-dynamic {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;

    .select-standard {
      width: 50%;
    }
  }

  &__left-column,
  &__right-column {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
    width: 50%;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 20px;
  }

  &__circular-spinner {
    color: #456aed;
  }

  &__message-success {
    font-size: 0.9em;
    font-family: 'Avenir Next GEO W05 Demi';
    color: #28a745;
    padding-left: 10px;
  }

  &__funnel-steps {
    margin: 20px 0;
    text-align: center;

    h3 {
      font-family: 'Avenir Next Geo W05 Demi';
      font-size: 16px;
      margin-bottom: 10px;
    }

    .funnel-steps__arrows {
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    .funnel-step__arrow {
      width: 60px;
      height: 30px;
      background-color: #ddd;
      clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 20% 50%);
      cursor: pointer;
      margin-left: -12px; // Ajuste para sobrepor as setas e criar o efeito de encaixe

      &.first {
        clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 0 50%);
        margin-left: 0; // A primeira seta não precisa do encaixe
      }

      &.active {
        background-color: #6e89ff; // Cor para a seta ativa
      }
    }

    .funnel-step__label {
      margin-top: 10px;
      font-family: 'Avenir Next Geo W05 Regular';
      font-size: 14px;
      color: #666;
    }
  }
}
