.policy-owners {
  display: flex;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;

    &--main-panel {
      margin: 0;
      min-height: 100vh;
    }
  }

  &__consult {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    justify-content: space-between;
    width: 100%;

    &--presentation-container {
      display: flex;
      flex-direction: column;
    }

    &--link-container {
      margin-right: 10px;
      display: flex;
    }

    &--title {
      margin: 1px 10px 3.7rem 0;
      color: var(--color-grey);

      h1 {
        font-size: 1.2rem;
      }
    }

    &--search {
      display: flex;
      flex-direction: column;

      label {
        color: var(--color-grey);
        font-weight: 500;
        font-size: 16px;
      }

      input {
        font-family: 'Avenir Next GEO W05 Regular';
        background-color: #fff;
        border: 1px solid var(--color-tab-dark-grey);
        color: var(--color-grey);
        border-radius: 5px;
        padding: 5px;
        font-size: 14px;
        margin-top: 7px;
        width: 270px;
        text-align: start;
        height: 32px;
        &::placeholder {
          text-align: start;
          color: #c1c1c1;
          padding-left: 3px;
        }
    
        &:focus-visible {
          outline-color: var(--color-tab-dark-grey);
        }
      }
    }

    &--filter-container {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
    }
    
    &--filter-container-label {
      padding-left: 10px;
    }

    &--filter {
      display: flex;
      align-items: center;
      justify-self: center;

      label {
        color: var(--color-grey);
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
