.filter-movs {
  display: grid;
  grid-template-columns: 350px 280px 300px 80px 200px;
  grid-gap: 30px;
  align-items: end;
  margin-bottom: 30px;
  box-sizing: border-box;
}

.filter-movs-content {
  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 16px;
  }

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 100%;
    text-align: start;
    margin-top: 5px;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 14px;
    }
  }
}

.filter-movs-radio-container {
  display: flex;
  flex-direction: row;
  .MuiSvgIcon-root {
    font-size: 16px;
  }

  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
  }
}

.filter-movs-date-period {
  margin-bottom: 8px;
  width: max-content;

  &:hover {
    cursor: pointer;
  }
}

.filter-movs-date-period-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 230px;

  &__radio-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__radio {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }
}

.filter-movs-date-period-container-datepicker {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
  }

  &__input {
    border: 2px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    margin-left: 5px;
    height: 25px;
    width: 100px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }
  }
}

.filter-movs-radio-group {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 120px;

  &__radio {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
  }

  &__label {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 14px;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }
}
