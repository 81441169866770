.commissioning {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  min-height: 60vh;
}

.commissioning-content {
  margin-left: 40px;
  transition: all .6s ease-in-out;
  box-sizing: border-box;

  &--form-open {
    display: flex;
    transition-delay: 0.6s;
    transition: all .6s ease-in-out;
  }

  &--form-close {
    display: flex;
    transition: all .4s ease-in-out;
  }
}

.commissioning-table {
  min-height: 200px;
}

.commissioning-table-header {
  display: flex;
  justify-content: center;
  justify-content: flex-start;
  z-index: -1;

  &__theader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    border: 2px solid var(--color-light-blue-alt);
    border-radius: 10px;
    background-color: var(--color-light-blue-alt);
    align-items: center;
    height: 50px;
    width: 500px;
  }

  &__th {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16px;
    text-align: center;
    color: white;
  }
}

.commissioning-table-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: overlay;
  max-height: 200px;

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    box-shadow: none;
    border-radius: 14px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 14px;
  }

  &__tbody {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-disabled-grey);
    border-radius: 10px;
    margin-top: 5px;
    min-height: 50px;
    max-height: 50px;
  }

  &__td {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    line-height: 15px;
    column-gap: 10px;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    width: 500px;

    p {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 16px;
      color: var(--color-grey);
    }
  }
}

.commissioning-form {
  margin-left: 10px;

  &--open {
    color: rgb(30, 37, 82);
    transition: all 0.5s;

    &:hover {
      cursor: pointer;
    }
  }

  &--close {
    color: rgb(30, 37, 82);
    transform: rotate(45deg);
    transition: all 0.5s;

    &:hover {
      cursor: pointer;
    }
  }

  &--visible {
    transition: all .4s ease-in-out;
    overflow: hidden;
    min-height: 380px;
  }

  &--hidden {
    overflow: hidden;
    transition: all .6s ease-in-out;
    height: 0px;
  }
}

.commissioning-form-content {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--color-tab-light-grey);
  border-radius: 4px;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  margin-top: 20px;
  min-height: 173px;
  width: 50%;
}

.commissioning-form-content-radio {
  display: flex;
  flex-direction: row;
  gap: 10px;

  &__radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    .MuiSvgIcon-root {
      font-size: 14px;
    }
  }
}

.commissioning-form-agency-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.commissioning-form-agency-container--more {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border: 2px solid var(--color-tab-light-grey);
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  height: 70px;
  width: 95%;
}

.commissioning-form-agency {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;

  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 14px;
    color: var(--color-subtle-grey);
  }

  &__radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    .MuiSvgIcon-root {
      font-size: 14px;
    }
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 100%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 100%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 14px;
    }
  }

  &__button-container {
    padding-top: 25px;
  }
}

.commissioning-form-agency-table-container {
  margin-top: 10px;
  width: 100%;
}

.commissioning-form-agency-table {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &__theader-tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 30px;
    border: 1px solid var(--color-tab-light-grey);
    
    th:last-child {
      border-right: none;
    }
  }

  &__theader-th {
    text-align: left;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Demi';
    color: var(--color-grey);
    border-right: 1px solid var(--color-tab-light-grey);
    padding: 5px;
  }

  &__tbody-tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 30px;
    border: 1px solid var(--color-tab-light-grey);
    border-top: none;

    td:last-child {
      border-right: none;
    }
  }

  &__tbody-td {
    text-align: left;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);
    border-right: 1px solid var(--color-tab-light-grey);
    padding: 5px;
  }
}

