.modal-dark-bg-documents-list {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  -webkit-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  animation: fade-in 0.225s forwards;

  &::after {
    animation: fade-out 0.3s forwards;
  }

  .leave {
    animation: down-up 0.2s forwards;
  }
}

@keyframes fade-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fade-out {
  from {
    background-color: rgba(0, 0, 0, 0.7);
  }
  to {
    background-color: transparent;
  }
}

@keyframes down-up {
  from {
    transform: translateY(calc(50% - 200px));
  }
  to {
    transform: translateY(-100%);
  }
}

.modal-documents-list {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.documents-list {
  background: var(--color-tab-light-grey);
  border-radius: 4px;
  height: 330px;
  width: 500px;

  &__title {
    display: flex;
    justify-content: flex-start;
    padding: 15px 15px 20px 20px;
  }
}

.documents-list-content {
  background-color: #fff;
  border: 2px solid var(--color-tab-dark-grey);
  margin: 10px 20px 20px 20px;
  padding: 10px 0px 10px 0px;
  height: 120px;
  overflow-y: auto;
}

.documents-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  &__link {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 12px;
    padding: 5px;
  }
}

.documents-list-warning {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  &__span {
    color: red;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 14px;
  }
}

.documents-list-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 10px;
}
