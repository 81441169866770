.confirm-action-Modal {
  display: flex;
  flex-direction: column;
  align-self: center;
  row-gap: 30px;
  transform: translateX(100%) translateY(180%);

  border-radius: 6px;
  background-color: var(--color-background-grey);
  padding: 15px 20px;
  height: 120px;
  width: 500px;

  &__span {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 18px;
  }

  .confirm-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}
