.demands-users {
  display: flex;
  flex-direction: column;

  &__title {
    font-family: 'Avenir Next GEO W05 Bold';
    font-size: 20px;
    color: var(--color-grey);
  }
}

.demands-users-table {
  border-top: 2px solid var(--color-border-grey);
  border-left: 2px solid var(--color-border-grey);
  border-right: 2px solid var(--color-border-grey);
  border-radius: 6px;
  margin-top: 5px;

  &__theader {
    text-align: left;
    position: sticky;
    background-color: none;
  }

  &__theader-tr {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;

    th:last-child {
      font-family: 'Avenir Next GEO W05 Regular';
      text-align: left;
      color: var(--color-grey);
      font-size: 14px;
      border-bottom: 2px solid var(--color-border-grey);
      padding: 10px;
    }
  }

  &__theader-th {
    font-family: 'Avenir Next GEO W05 Regular';
    text-align: left;
    color: var(--color-grey);
    font-size: 14px;
    border-right: 2px solid var(--color-border-grey);
    border-bottom: 2px solid var(--color-border-grey);
    padding: 10px;
  }

  &__tbody {
    text-align: left;
    width: 100%;
  }

  &__tbody-tr {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;

    td:first-child {
      font-family: 'Avenir Next Geo W05 Regular';
      color: var(--color-grey);
      font-size: 12px;
      text-align: left;
      border-right: 2px solid var(--color-border-grey);
      border-bottom: 2px solid var(--color-border-grey);
      padding: 10px;
    }

    td:last-child {
      font-family: 'Avenir Next Geo W05 Regular';
      color: var(--color-grey);
      font-size: 12px;
      text-align: left;
      border-bottom: 2px solid var(--color-border-grey);
      padding: 10px;
    }
  }

  &__tbody-td {
    font-family: 'Avenir Next Geo W05 Regular';
    color: var(--color-grey);
    font-size: 12px;
    text-align: left;
    border-right: 2px solid var(--color-border-grey);
    border-bottom: 2px solid var(--color-border-grey);
    padding: 10px;
    cursor: pointer;

    &:hover {
      font-family: 'Avenir Next GEO W05 Demi';
      color: var(--primary-color);
      cursor: pointer;
    }
  }
}
