@font-face {
  font-family: 'Avenir Next GEO W05 Regular';
  src: local('Avenir Next GEO W05 Regular'),
    local('Avenir Next GEO W05 Regular'),
    url('./assets/fonts/AvenirNextGEO/Regular/281f890c-8412-4ee3-84ed-8b5d062d2ab8.woff2')
      format('woff2'),
    url('./assets/fonts/AvenirNextGEO/Regular/5729f02e-f6b0-4f35-8ee5-c2cffa65fa76.woff')
      format('woff');
}

@font-face {
  font-family: 'Avenir Next GEO W05 Medium';
  src: local('Avenir Next GEO W05 Medium'), local('Avenir Next GEO W05 Medium'),
    url('./assets/fonts/AvenirNextGEO/Medium/1fed34fa-250a-4d32-9f1d-42f978a2e0b2.woff2')
      format('woff2'),
    url('./assets/fonts/AvenirNextGEO/Medium/e57662ff-b1ef-4122-88c1-61bbdabeb365.woff')
      format('woff');
}

@font-face {
  font-family: 'Avenir Next GEO W05 Demi';
  src: local('Avenir Next GEO W05 Demi'), local('Avenir Next GEO W05 Demi'),
    url('./assets/fonts/AvenirNextGEO/Demi/2200dfff-da50-40b0-bc12-5e4b872a1998.woff2')
      format('woff2'),
    url('./assets/fonts/AvenirNextGEO/Demi/dc10b3bd-5076-4df5-a5f5-e5961f4a6938.woff')
      format('woff');
}

@font-face {
  font-family: 'Avenir Next GEO W05 Bold';
  src: local('Avenir Next GEO W05 Bold'), local('Avenir Next GEO W05 Bold'),
    url('./assets/fonts/AvenirNextGEO/Bold/282b2a26-6d49-4573-aeda-df22b21b024b.woff2')
      format('woff2'),
    url('./assets/fonts/AvenirNextGEO/Bold/bb885000-2f34-4c93-8370-6c3b83b39e20.woff')
      format('woff');
}

@font-face {
  font-family: 'Avenir Next GEO W05 Heavy';
  src: local('Avenir Next GEO W05 Heavy'), local('Avenir Next GEO W05 Heavy'),
    url('./assets/fonts/AvenirNextGEO/Heavy/ea3856c5-f5d3-4c02-af69-c0377f5e2d8d.woff2')
      format('woff2'),
    url('./assets/fonts/AvenirNextGEO/Heavy/307a99de-7ecb-41df-a107-7cce323453e7.woff')
      format('woff');
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Avenir Next GEO W05 Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

button:focus {
  outline: none;
}

input {
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
}

/* input[type="checkbox"]{
  -webkit-appearance: checkbox;
} */

input::placeholder {
  font-family: 'Avenir Next GEO W05 Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: normal;
}

input::placeholder {
  color: #000;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
}

::-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
}

::-moz-placeholder {
  /* Firefox 18- */
  color: #000;
}

::-moz-selection {
  background: grey;
  color: #000;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background: transparent;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #dcdcdc;
  border-radius: 14px;
}

::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  /* margin: 10; */
  box-shadow: inset 0 0 8px #dcdcdc;
  border-radius: 14px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
  /* margin: 10; */
  box-shadow: outset 0 0 12px #dcdcdc;
  border-radius: 14px;
  transition: 5s;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

table {
  font-family: 'Avenir Next GEO W05 Regular';
}

th {
  font-family: 'Avenir Next GEO W05 Regular';
}

span.MuiTypography-root {
  font-family: 'Avenir Next GEO W05 Regular';
  font-size: 0.9rem;
}

.MuiMenu-list {
  outline: 0;
  max-height: 400px;
}
