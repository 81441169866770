.manager-request {
  display: flex;
  background-color: #fff;

  .manager-request-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__main {
      background-color: #fff;
      padding: 20px 45px;
      height: 100%;
      width: calc(100% - 180px);

      &--painel {
        max-width: calc(100vw - 170px);
      }
    }
  }

  .manager-request-content-title {
    padding: 40px;
    padding-left: 0;

    &__title {
      font-family: 'Avenir Next GEO W05 Heavy';
      font-size: 20px;
      color: var(--color-grey);
    }
  }

  .manager-request-content {
    display: flex;
    flex-direction: row;
    column-gap: 20px;

    &__input-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
      height: 100px;
      width: 300px;
    }

    &__label {
      display: block;
      width: 100%;
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 14px;
      color: var(--color-grey);
      margin-bottom: 8px;
    }

    &__input-group {
      display: flex;
      width: 100%;
      gap: 8px;
    }

    &__input {
      flex: 1;
      background-color: #fff;
      border: 1px solid var(--color-tab-dark-grey);
      color: var(--color-grey);
      border-radius: 6px;
      font-size: 14px;
      padding: 5px;
      height: 32px;
      &::placeholder {
        text-align: start;
        color: #c1c1c1;
      }
      &:focus-visible {
        outline-color: var(--color-tab-dark-grey);
      }
    }

    &__checkbox-content {
      padding: 15px;
      height: 100px;
      width: 400px;
    }

    &__button-content {
      display: flex;
      gap: 8px;
      align-items: center;
      padding-bottom: 16px;
    }
  }
}