.ds-checkbox {
  display: flex;
  flex-direction: row;
  padding-left: 3px;
  align-items: center;

  &__input {
    all: unset;
  }

  &__custom {
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;

    &--small {
      height: 10px;
      width: 10px;
    }

    &--medium {
      height: 12px;
      width: 12px;
    }

    &--large {
      height: 14px;
      width: 14px;
    }

    &--checked-broker {
      background-color: var(--color-primary-blue-hover);
      border-color: var(--color-primary-blue-hover);
      background-image: url('../../../assets/imgs/checked-white.png'); // Caminho para a imagem do ícone de checked
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &--checked-hr {
      background-color: var(--color-light-blue-alt);
      border-color: var(--color-light-blue-alt);
      background-image: url('../../../assets/imgs/checked-white.png'); // Caminho para a imagem do ícone de checked
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      background-color: white;
      transition: transform 0.3s;
      transform: scale(0);
    }
  }

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    margin-left: 10px;
    margin-bottom: 5px;
    cursor: pointer;

    &--small {
      font-size: 12px;
    }

    &--medium {
      font-size: 14px;
    }

    &--large {
      font-size: 16px;
    }
  }
}
