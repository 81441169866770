.edit-activities {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 0.5fr 1fr 0.5fr 0.5fr;
  align-items: center;
  column-gap: 20px;

  &__grey {
    font-family: Avenir Next Geo W05 Demi;
    color: #3B3838;
  }

  &__green {
    font-family: Avenir Next Geo W05 Demi;
    color: #008631;
  }

  &__yellow {
    font-family: Avenir Next Geo W05 Demi;
    // color: #fff266;
  }

  &__red {
    font-family: Avenir Next Geo W05 Demi;
    color: #ff3e3e;
  }

  &__select {
    font-size: 14px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 6px;
    padding-left: 10px;
    background-color: #fff;
    cursor: pointer;
    width: 140px;

    &:hover {
      border-color: var(--color-tab-dark-grey);
    }

    &:focus {
      border: 1px solid #e5e5e5;
      outline: none;
    }

    &:focus-visible {
      border: 1px solid #e5e5e5;
      outline: none;
    }
  }

  &__buttons-container {
    display: flex;
  }

  &__button-edit {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    height: 25px;
  }
}

.edit-activities-description {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    margin-top: 7px;
    width: 250px;
    text-align: start;
    height: 32px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }
}

.edit-activities-datepicker {
  display: flex;
  align-items: center;

  &__input-date {
    border: 1px solid var(--color-tab-dark-grey);
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 33px;
    width: 100px;

    &:focus-visible {
      outline: 1px var(--color-tab-dark-grey) auto;
    }
  }
}