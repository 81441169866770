.popper-delete-confirmation {
  width: 500px;
  height: 80px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__message {
    font-weight: bold;
  }
}

.popper-options-container {
  font-family: 'Avenir Next GEO W05 Regular';
  margin-top: 5px;

  &__options {
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
  
    &:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }

  &__check-icon {
    margin-right: 2px;
    color: green;
  }

  &__cross-icon {
    margin-right: 2px;
    color: red;
  }
}

