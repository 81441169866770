.request-inclusion-plan-form {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-top: 45px;
  padding-left: 50px;

  &__type-plan {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    h4 {
      color: var(--color-text);
      font-family: 'Avenir Next GEO W05 Demi';
    }
  }

  &__label {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 16.5px;
  }

  &__select {
    width: 400px;
  }

  &__checkbox-container {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
  }

  &__insurance-plan {
    display: flex;
    flex-direction: column;
  }

  &__title-content {
    margin-bottom: 10px;

    h4 {
      color: var(--color-text);
      font-family: 'Avenir Next GEO W05 Demi';
    }
  }

  &__health-plan {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__box-size {
    margin-bottom: 20px;
    width: 400px;
  }
}
