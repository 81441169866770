.movs-table-body {
  display: grid;
  grid-template-columns: 26px 60px 1.5fr 0.75fr 0.5fr 1.5fr 0.5fr 0.5fr 80px;
  grid-gap: 10px;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  align-items: center;
  box-sizing: border-box;
  height: 50px;

  &:hover {
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;
    background-color: var(--color-tab-light-grey);
  }

  &__checkbox {
    margin-left: 7px;
    svg {
      height: 18px;
      width: 18px;
      color: var(--color-dark-blue);
    }
  }

  &__label {
    text-align: left;
    font-size: 14px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: var(--color-grey);

    &.truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 10px;
      display: block;
    }
  }

  &__icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    color: var(--color-dark-blue);
  }

  &__icon-visible {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
    color: var(--color-dark-blue);
    cursor: pointer;
  }

  &__fire-icon {
    height: 30px;
    width: 30px;
    margin-bottom: 5px;
  }
}

.movs-table-body--expanded {
  display: grid;
  grid-template-columns: 26px 60px 1.5fr 0.75fr 0.5fr 1.5fr 0.5fr 0.5fr 80px;
  grid-gap: 10px;
  border: 2px solid var(--color-tab-light-grey);
  border-top: none;
  border-bottom: none;
  align-items: center;
  box-sizing: border-box;
  height: 50px;
}

.movs-table-body-mov-type {
  position: relative;
  z-index: 0;
  text-align: center;

  &[data-type="Inclusão"] svg {
    color: #43a047 !important;
  }
  
  &[data-type="Exclusão"] svg {
    color: #e53935 !important
  }

  &__legend {
    visibility: hidden;
    width: 80px;
    background-color: var(--primary-color);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    top: 100%;
    margin-left: -40px;
    z-index: 999;

    opacity: 0.8;
  }

  &:hover {
    .movs-table-body-mov-type__legend {
      visibility: visible;
    }
  }
}
