.fourth-step {
  width: 450px;
  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    &--index-form {
      margin: 20px 0;
    }
  }

  &__p {
    font-family: 'Avenir Next GEO W05 Demi';
    margin-bottom: 10px;
  }

  &__container-title {
    margin-bottom: 20px;
  }

  &__title {
    color: var(--color-grey);
    font-family: 'Avenir Next GEO W05 Bold';
    font-size: 18px;
  }

  &__label {
    padding-left: 15px;
  }

  .fourth-step-borth-date {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    &__span {
      color: var(--color-grey);
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      padding-left: 15px;
    }

    &__custom-date-picker-width,
    &__custom-date-picker-width > div.react-datepicker-wrapper,
    &__custom-date-picker-width > div > div.react-datepicker__input-container,
    &__custom-date-picker-width > div > div.react-datepicker__input-container input {
      width: 100%;
    }

    &__date-picker {
      color: var(--color-dark-gray);
      background: #fff;
      border: 2px solid var(--color-primary-blue);
      border-radius: 48px;
      text-align: center;
      height: 48px;
    }
  }

  .fourth-step-rg-information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
    margin-top: 10px;

    &__text-container {
      width: 200px;
    }

    &__date-container {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &__span {
      color: var(--color-grey);
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      padding-left: 15px;
    }
  
    &__custom-date-picker-width,
    &__custom-date-picker-width > div.react-datepicker-wrapper,
    &__custom-date-picker-width > div > div.react-datepicker__input-container,
    &__custom-date-picker-width > div > div.react-datepicker__input-container input {
      width: 200px;
    }

    &__date-picker {
      color: var(--color-dark-gray);
      background: #fff;
      border: 2px solid var(--color-primary-blue);
      border-radius: 48px;
      text-align: center;
      height: 48px;
    }
  }

  &__button-add-dependent {
    padding: 30px 10px;
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-top: 20px;
  }

  &__button-remove-dependent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .fourth-step-documents {
    &__span {
      color: var(--color-grey);
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
    }

    &__message-error {
      color: red;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 13px;
      padding: 0px;
      padding-left: 15px;
    }
  }

  .fourth-step-documents-list-docs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    span {
      font-size: 14px;
    }
  }

  .fourth-step-save {
    padding: 10px;

    div:nth-last-child(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding-left: 15px;
    }

    &__span {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      color: var(--color-grey);
      margin-bottom: 10px;
      display: block;
    }

    &__span-success {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 16px;
      color: var(--color-green);
    }
  }
}
