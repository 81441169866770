.broker-beneficiaries-filter {
  display: grid;
  grid-template-columns: 350px 1fr;
  column-gap: 30px;
  margin-bottom: 15px;
  height: 180px;
  width: max-content;
}

.broker-beneficiaries-filter-col {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.broker-beneficiaries-filter-col-content {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 16px;
    color: var(--color-grey);
  }

  &__select {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 95%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }

    option {
      font-size: 14px;
    }
  }

  &__input {
    font-family: 'Avenir Next GEO W05 Regular';
    background-color: #fff;
    border: 1px solid var(--color-tab-dark-grey);
    color: var(--color-grey);
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    width: 95%;
    text-align: start;
    height: 30px;
    &::placeholder {
      text-align: start;
      color: #c1c1c1;
      padding-left: 3px;
    }

    &:focus-visible {
      outline-color: var(--color-tab-dark-grey);
    }
  }
}

.selectbox-first-row-container {
  display: grid;
}

.broker-beneficiaries-filter-checkbox-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;
    color: var(--color-grey);
  }
}

.broker-beneficiaries-filter-radio-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 14px;
    color: var(--color-grey);
  }
}

.broker-beneficiaries-filter-contract-container {
  display: flex;
  flex-direction: row;

  &__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 16px;
    color: var(--color-grey);
  }

  &--no-plans {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.caroussel-container {
  display: flex;
  align-items: center;

  span {
    padding: 5px;
  }

  .caroussel-items-column {
    display: flex;
    max-width: 760px;
    overflow-x: hidden;
    margin-right: 8px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .caroussel-item {
    white-space: nowrap;
  }

  svg {
    margin-top: -2px;
    padding: 5px;
    &:hover {
      cursor: pointer;
      padding: 5px;
      background-color: #f0f0f0;
      border-radius: 17px;
    }
  }
}