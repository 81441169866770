.ds-link {
  background: transparent;
  border: none;
  display: flex;
  gap: 4px;
  padding: 4px 8px;
  color: rgb(69, 91, 113);

  font-size: 16px;
  cursor: pointer;
  transition: all 0.4s;
  font-weight: 600;
  letter-spacing: normal;
}
