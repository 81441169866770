.tasks-content {
  display: flex;
  flex-direction: column;
}

.tasks-settings {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  padding-top: 50px;

  &__button {
    padding-top: 24px;
  }
}
